import { Divider, Grid, Typography } from "@mui/material";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import { toTitleCase } from "../../../helpers/validationHelpers";
import moment from "moment";
import { Fragment } from "react";

const BankingTab = ({ stripeConnectProfile }: any) => {
  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      rowSpacing={2}
      container
      direction={"column"}
      justifyContent={"space-evenly"}
    >
      <Grid item pb={2}>
        <Typography fontWeight={600} fontSize={16}>
          Debit cards and bank accounts
        </Typography>
      </Grid>

      {stripeConnectProfile?.stripeConnectPaymentMethods.map(
        (paymentMethod: any, index: number) => {
          if (paymentMethod.stripeConnectDebitCard) {
            return (
              <Fragment
                key={`banking-information-payment-methods-section-${index}`}
              >
                <Grid container spacing={1} direction={"column"}>
                  <ProfileListItem
                    label={"Payment Method Type"}
                    value={"Debit Card"}
                  />
                  <ProfileListItem
                    label={"Name on Card"}
                    value={paymentMethod?.stripeConnectDebitCard?.nameOnCard}
                  />
                  <ProfileListItem
                    label={"Brand"}
                    value={toTitleCase(
                      paymentMethod?.stripeConnectDebitCard?.brand
                    )}
                  />
                  <ProfileListItem
                    label={"Expiry Month"}
                    value={paymentMethod?.stripeConnectDebitCard?.expiryMonth}
                  />
                  <ProfileListItem
                    label={"Expiry Year"}
                    value={paymentMethod?.stripeConnectDebitCard?.expiryYear}
                  />
                  <ProfileListItem
                    label={"Default payment method"}
                    value={paymentMethod?.isDefault ? "Yes" : "No"}
                  />
                  <ProfileListItem
                    label={"Created At"}
                    value={moment(
                      paymentMethod?.stripeConnectDebitCard?.createdAt
                    ).format("Do MMMM  YYYY, h:mm a")}
                  />
                  <ProfileListItem
                    label={"Last Updated At"}
                    value={moment(
                      paymentMethod?.stripeConnectDebitCard?.updatedAt
                    ).format("Do MMMM  YYYY, h:mm a")}
                  />
                </Grid>
                {index !==
                  stripeConnectProfile?.stripeConnectPaymentMethods?.length -
                    1 && <Divider sx={{ marginY: 2 }} />}
              </Fragment>
            );
          } else {
            return (
              <Fragment
                key={`banking-information-payment-methods-section-${index}`}
              >
                <Grid container spacing={1} direction={"column"}>
                  <ProfileListItem
                    label={"Payment Method Type"}
                    value={"Bank Account"}
                  />
                  <ProfileListItem
                    label={"Account holder name"}
                    value={
                      paymentMethod?.stripeConnectBankAccount?.accountHolderName
                    }
                  />
                  <ProfileListItem
                    label={"Account Number Last Four"}
                    value={toTitleCase(
                      paymentMethod?.stripeConnectBankAccount
                        ?.accountNumberLastFour
                    )}
                  />
                  <ProfileListItem
                    label={"Routing Number"}
                    value={
                      paymentMethod?.stripeConnectBankAccount?.routingNumber
                    }
                  />
                  <ProfileListItem
                    label={"Country"}
                    value={paymentMethod?.stripeConnectBankAccount?.country}
                  />
                  <ProfileListItem
                    label={"Default payment method"}
                    value={paymentMethod?.isDefault ? "Yes" : "No"}
                  />
                  <ProfileListItem
                    label={"Created At"}
                    value={moment(
                      paymentMethod?.stripeConnectBankAccount?.createdAt
                    ).format("Do MMMM  YYYY, h:mm a")}
                  />
                  <ProfileListItem
                    label={"Last Updated At"}
                    value={moment(
                      paymentMethod?.stripeConnectBankAccount?.updatedAt
                    ).format("Do MMMM  YYYY, h:mm a")}
                  />
                </Grid>
                {index !==
                  stripeConnectProfile?.stripeConnectPaymentMethods?.length -
                    1 && <Divider sx={{ marginY: 2 }} />}
              </Fragment>
            );
          }
        }
      )}
    </Grid>
  );
};

export default BankingTab;
