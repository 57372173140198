import { Container } from "@mui/material";
import DashboardNavBar from "./DashboardNavBar/DashboardNavBar";

const DashboardLayout = ({ children, loading }: any) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ height: "100vh", backgroundColor: "#f5f5f5" }}
    >
      <DashboardNavBar loading={loading} />
      <Container
        maxWidth={false}
        disableGutters
        sx={{ px: "40px", backgroundColor: "#f5f5f5" }}
      >
        {children}
      </Container>
    </Container>
  );
};

export default DashboardLayout;
