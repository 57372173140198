import { Alert, Box, Grid, Snackbar, Typography } from "@mui/material";
import Modal from "../../../../../components/Modal/Modal";
import { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  isAnyKeyValueTrue,
  validateCardNumber,
  validateEmail,
  validatePhoneNumber,
  validatePostalCode,
  validatePresence,
} from "../../../../../helpers/validationHelpers";
import AddCreditCardPage from "./AddCreditCardPage";
import AddBillingAddressPage from "./AddBillingAddress";
import moment from "moment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addNewCompanyPaymentMethodAndBillingAddress,
  updateCompanyPaymentMethodAndBillingAddress,
} from "../../../../../hooks/useCompanyPaymentMethods";

const PaymentMethodsModal = ({
  accessToken,
  companyId,
  editMode = false,
  companyPaymentMethodData,
  companyBillingDetailsData,
  open,
  setOpen,
}: {
  accessToken: string;
  companyId: string;
  editMode?: boolean;
  companyPaymentMethodData?: any;
  companyBillingDetailsData?: any;
  open: boolean;
  setOpen: any;
}) => {
  const queryClient = useQueryClient();
  const currentDate = moment();
  const [submitError, setSubmitError] = useState<any>("");
  const [showSuccess, setShowSuccess] = useState(false);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  // credit card page (page 1)
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [expiryDate, setExpiryDate] = useState(moment());
  const [ccv, setCcv] = useState("");

  // billing address page (page 2)
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [billingAddressPostalCode, setBillingAddressPostalCode] = useState("");

  const resetFields = () => {
    setPage(0);
    setOpen(false);

    setNameOnCard("");
    setCardNumber("");
    setPostalCode("");
    setExpiryDate(moment());
    setCcv("");

    setStreet("");
    setCity("");
    setProvince("");
    setBillingAddressPostalCode("");
  };

  useEffect(() => {
    if (editMode && companyPaymentMethodData) {
      // credit card data
      setNameOnCard(companyPaymentMethodData?.nameOnCard);
      setCardNumber("");
      setPostalCode(companyPaymentMethodData?.postalCode?.toUpperCase());
      setCcv("");
      const parsedDate = moment(
        `${companyPaymentMethodData?.expiryMonth}/${companyPaymentMethodData?.expiryYear}`,
        "M/YYYY"
      );
      setExpiryDate(parsedDate);
    }
  }, [editMode, companyPaymentMethodData]);

  useEffect(() => {
    if (editMode && companyBillingDetailsData) {
      // credit card data
      setStreet(companyBillingDetailsData?.street);
      setCity(companyBillingDetailsData?.city);
      setProvince(companyBillingDetailsData?.province);
      setBillingAddressPostalCode(companyBillingDetailsData?.postalCode);
    }
  }, [editMode, companyBillingDetailsData]);

  const handleBackPress = () => {
    if (page === 0) {
      setOpen(false);
    } else {
      setPage((prev) => prev - 1);
    }
  };

  const submitDisabled = {
    creditCardPage: {
      nameOnCard: !validatePresence(nameOnCard),
      cardNumber:
        !validatePresence(cardNumber) || !validateCardNumber(cardNumber),
      postalCode:
        !validatePresence(postalCode) || !validatePostalCode(postalCode),
      expiryDate:
        !moment(expiryDate, "MM/YY", true).isValid() ||
        (moment(expiryDate, "MM/YY", true).isValid() &&
          !moment(expiryDate, "MM/YY", true).isAfter(currentDate)),
      ccv: !validatePresence(ccv) || ccv.length < 3,
    },
    billingAddressPage: {
      street: !validatePresence(street),
      city: !validatePresence(city),
      province: !validatePresence(province),
      billingAddressPostalCode:
        !validatePresence(billingAddressPostalCode) ||
        !validatePostalCode(billingAddressPostalCode),
    },
  };

  const creditCardPageDisabled = Boolean(
    isAnyKeyValueTrue(submitDisabled?.creditCardPage)
  );

  const billingAddressPageDisabled = Boolean(
    isAnyKeyValueTrue(submitDisabled?.billingAddressPage)
  );

  const {
    mutate: handleAddNewPaymentMethod,
    isLoading: isAddNewPaymentMethodLoading,
  } = useMutation({
    mutationFn: addNewCompanyPaymentMethodAndBillingAddress,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["company-payment-methods", companyId],
      });

      await queryClient.cancelQueries({
        queryKey: ["company-billing-settings", companyId],
      });

      // const cachedPaymentMethodsData = queryClient.getQueryData([
      //   "company-payment-methods",
      // ]);
      // if (cachedPaymentMethodsData) {
      //   // this is just an object for now, not an array
      //   queryClient.setQueryData(
      //     ["company-payment-methods"],
      //     data?.companyStripePaymentMethod
      //   );
      // }

      queryClient.setQueryData(
        ["company-payment-methods", companyId],
        data?.companyStripePaymentMethod
      );

      // const cachedBillingSettingsData = queryClient.getQueryData([
      //   "company-billing-settings",
      // ]);

      // if (cachedBillingSettingsData) {
      //   queryClient.setQueryData(["company-billing-settings"], (prev: any) => {
      //     return {
      //       ...prev,
      //       companyBillingDetails: data?.companyBillingDetails,
      //     };
      //   });
      // }

      queryClient.setQueryData(
        ["company-billing-settings", companyId],
        (prev: any) => {
          return {
            ...prev,
            companyBillingDetails: data?.companyBillingDetails,
          };
        }
      );

      // setOpen(false);
      setPage(0);
      setOpen(false);

      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error adding new payment method", error);
      setSubmitError(error);
    },
  });

  const {
    mutate: handleUpdatePaymentMethod,
    isLoading: isUpdatePaymentMethodLoading,
  } = useMutation({
    mutationFn: updateCompanyPaymentMethodAndBillingAddress,
    onSuccess: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ["company-payment-methods", companyId],
      });

      await queryClient.cancelQueries({
        queryKey: ["company-billing-settings", companyId],
      });

      const cachedPaymentMethodsData = queryClient.getQueryData([
        "company-payment-methods",
        companyId,
      ]);
      if (cachedPaymentMethodsData) {
        // this is just an object for now, not an array
        queryClient.setQueryData(
          ["company-payment-methods", companyId],
          data?.companyStripePaymentMethod
        );
      }

      const cachedBillingSettingsData = queryClient.getQueryData([
        "company-billing-settings",
        companyId,
      ]);

      if (cachedBillingSettingsData) {
        queryClient.setQueryData(
          ["company-billing-settings", companyId],
          (prev: any) => {
            return {
              ...prev,
              companyBillingDetails: data?.companyBillingDetails,
            };
          }
        );
      }

      setPage(0);
      setOpen(false);

      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error adding new payment method", error);
      setSubmitError(error);
    },
  });

  const handleFinalSubmit = () => {
    const formattedDate = moment(expiryDate, "MM/YY");
    console.log("formattedDate", formattedDate.month);

    if (editMode) {
      handleUpdatePaymentMethod({
        companyStripePaymentMethodId: companyPaymentMethodData?.id,
        accessToken,
        card: {
          nameOnCard,
          cardNumber,
          expiryMonth: formattedDate.month() + 1,
          expiryYear: formattedDate.year(),
          postalCode,
          cvc: ccv,
        },
        billingAddress: {
          street,
          city,
          province,
          postalCode: billingAddressPostalCode,
        },
        companyId,
      });
    } else {
      handleAddNewPaymentMethod({
        accessToken,
        companyId,
        card: {
          nameOnCard,
          cardNumber,
          expiryMonth: formattedDate.month() + 1,
          expiryYear: formattedDate.year(),
          postalCode,
          cvc: ccv,
        },
        billingAddress: {
          street,
          city,
          province,
          postalCode: billingAddressPostalCode,
        },
      });
    }
  };

  const pages = [
    {
      handleConfirmButtonPress: () => {
        // handleLocationPageSubmit();
        setPage((prev) => prev + 1);
      },
      handleBackPress,
      submitButtonLabel: "Next",
      cancelButtonLabel: "",
      component: (
        <AddCreditCardPage
          nameOnCard={nameOnCard}
          setNameOnCard={setNameOnCard}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          expiryDate={expiryDate}
          setExpiryDate={setExpiryDate}
          ccv={ccv}
          setCcv={setCcv}
        />
      ),
      withCancelButton: false,
      title: `${editMode ? "Update" : "Add"} Credit Card`,
      confirmButtonDisabled: creditCardPageDisabled,
      confirmButtonLoading: loading,
    },
    {
      handleConfirmButtonPress: () => {
        // handleLocationPageSubmit();
        handleFinalSubmit();
      },
      handleBackPress,
      submitButtonLabel: "Next",
      component: (
        <AddBillingAddressPage
          street={street}
          setStreet={setStreet}
          city={city}
          setCity={setCity}
          province={province}
          setProvince={setProvince}
          billingAddressPostalCode={billingAddressPostalCode}
          setBillingAddressPostalCode={setBillingAddressPostalCode}
        />
      ),
      withCancelButton: true,
      cancelButtonLabel: "Back",
      title: `${editMode ? "Update" : "Add"} Billing Address`,
      confirmButtonDisabled:
        creditCardPageDisabled || billingAddressPageDisabled,
      confirmButtonLoading:
        loading || isAddNewPaymentMethodLoading || isUpdatePaymentMethodLoading,
    },
  ];

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Payment method and billing settings updated
        </Alert>
      </Snackbar>
      <Modal
        loading={
          loading ||
          isAddNewPaymentMethodLoading ||
          isUpdatePaymentMethodLoading
        }
        open={open}
        onSubmit={pages[page].handleConfirmButtonPress}
        handleClose={() => {
          setOpen(false);
        }}
        withCancelButton={pages[page].withCancelButton}
        onCancel={pages[page].handleBackPress}
        submitButtonLabel={pages[page].submitButtonLabel}
        cancelButtonLabel={pages[page].cancelButtonLabel}
        title={pages[page].title}
        submitButtonDisabled={pages[page].confirmButtonDisabled}
      >
        {pages[page].component}
      </Modal>
    </>
  );
};

export default PaymentMethodsModal;
