import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Rating,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import { useDriverDetails } from "../../../hooks/useDrivers";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getAccessToken } from "../../../helpers/authHelpers";

import ProfilePanel from "../../../components/ProfilePanel/ProfilePanel";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";

import { useContext, useEffect, useRef, useState } from "react";
import { useServiceCallDetails } from "../../../hooks/useServiceCalls";
import moment from "moment";
import {
  calculateDistance,
  calculateElapsedTime,
  calculateFareEstimate,
  calculateTimeToDistanceInMinutes,
  roundToPrecision,
} from "../../../helpers/formatters";
import {
  PickUpStatus,
  ServiceDriverTypes,
  VehicleClassTypes,
  cancelledStatuses,
  customerCancelledStatuses,
  driverCancelStatuses,
  formatVehicleTypesForServiceCall,
  getVehicleTypesForServiceCall,
  inProgressStatuses,
  mappedStatuses,
  mappedVehicleTypes,
  toTitleCase,
} from "../../../helpers/validationHelpers";
import MapSection from "../../../components/GoogleMaps/GoogleMaps";
import GoogleMaps from "../../../components/GoogleMaps/GoogleMaps";
import {
  DirectionsRenderer,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import ImageModal from "../../../components/ImageModal/ImageModal";
import useElementSizeObserver from "../../../hooks/useElementSizeObserver";
import { AuthContext } from "../../../context/authContext";
import { useBillingSettings } from "../../../hooks/useBillingSettings";

const ServiceCallDetails = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  const { serviceCallId } = useParams();
  const { user } = useAuthenticator((context) => [context.route, context.user]);
  // const accessToken = getAccessToken(user);
  const { accessToken } = useContext(AuthContext);
  const { data: serviceCallData, isLoading } = useServiceCallDetails(
    accessToken,
    serviceCallId
  );

  const { data: billingSettingsData, isLoading: isBillingSettingsLoading } =
    useBillingSettings(accessToken);

  const [directionsResponse, setDirectionsResponse] = useState<any>();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageToLoad, setImageToLoad] = useState({ label: "", value: "" });

  const {
    width: customerCargoUploadsContainerWidth,
    height: customerCargoUploadsContainerHeight,
    elementRef: customerCargoUploadsRef,
  } = useElementSizeObserver();

  const customerCurrentServiceCallRatingEntry = serviceCallData?.ratings?.find(
    (rating: any) => rating.raterId === serviceCallData?.customer?.id
  );

  const driverCurrentServiceCallRatingEntry = serviceCallData?.ratings?.find(
    (rating: any) => rating.raterId === serviceCallData?.driver?.id
  );

  console.log("driverData", serviceCallData);
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
    tab: {
      textTransform: "capitalize",
    },
  };

  const renderVehicleType = () => {
    const vehicleTypes = getVehicleTypesForServiceCall(
      serviceCallData?.pickUpRequestDetails?.requestedVehicleClass,
      serviceCallData?.pickUpRequestDetails?.requestedVehicleType
    );

    return vehicleTypes?.length > 0
      ? formatVehicleTypesForServiceCall(vehicleTypes)
      : "N/A";
  };

  const driverPrimarySectionFields = [
    { label: "Email Address", value: serviceCallData?.driver?.email },
    { label: "Phone No.", value: serviceCallData?.driver?.phoneNumber },
    {
      label: "Service Tutorial Completed",
      value: serviceCallData?.driver?.driverRegistrationDetail
        ?.driverEvaluationCompleted
        ? "Yes"
        : "No",
    },
    {
      label: "Driver Cancellation %",
      value: serviceCallData?.driver?.driverCancellationRate,
    },
    {
      label: "Service Call Acceptance Percentage",
      value: serviceCallData?.driver?.driverAcceptanceRate,
    },
    {
      label: "Status",
      value: serviceCallData?.driver?.isOnline ? "Active" : "Offline",
    },
  ];

  const customerPrimarySectionFields = [
    { label: "Email Address", value: serviceCallData?.customer?.email },
    { label: "Phone No.", value: serviceCallData?.customer?.phoneNumber },
    {
      label: "Created At",
      value: moment(serviceCallData?.customer?.createdAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
    {
      label: "Updated At",
      value: moment(serviceCallData?.customer?.updatedAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
  ];

  const serviceCallPrimaryListSection = [
    {
      label: "Pick Up Address",
      value: serviceCallData?.pickUpRequestLocationDetails?.originAddress,
    },
    {
      label: "Vehicle ID",
      value:
        serviceCallData?.driver?.driverRegistrationDetail
          ?.driverVehicleInformation?.id,
    },
    {
      label: "Total Distance Travelled",
      value: serviceCallData?.distanceTravelledInMeters
        ? `${roundToPrecision(
            serviceCallData?.distanceTravelledInMeters / 1000,
            2
          )} KM`
        : "N/A",
    },
    {
      label: "Total Service Call Time",
      value: `${calculateElapsedTime(
        serviceCallData?.driverMarkedPickupAsInProgressTimestamp,
        serviceCallData?.driverArrivedAtDropOffLocationTimestamp
      )}`,
    },
    {
      label: "Inside/Threshold Delivery",
      value: serviceCallData?.pickUpRequestDetails?.insideThresholdDelivery
        ? "Yes"
        : "No",
    },
    ...(cancelledStatuses.includes(serviceCallData?.pickUpStatus)
      ? [
          {
            label: "Cancelled By",
            value: customerCancelledStatuses.includes(
              serviceCallData?.pickUpStatus
            )
              ? `${serviceCallData?.customer?.firstName} ${serviceCallData?.customer?.lastName}`
              : `${serviceCallData?.driver?.firstName} ${serviceCallData?.driver?.lastName}`,
          },
        ]
      : []),
    {
      label: "Customer Rating",
      value: (
        <Rating
          sx={{ color: "#3fbf5d" }}
          value={
            customerCurrentServiceCallRatingEntry
              ? Number(customerCurrentServiceCallRatingEntry?.rating)
              : 0
          }
          readOnly
        />
      ),
    },
    {
      label: "Created At",
      value: moment(serviceCallData?.createdAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
  ];

  const serviceCallSecondaryListSection = [
    {
      label: "Destination Address",
      value: serviceCallData?.pickUpRequestLocationDetails?.destinationAddress,
    },

    {
      label: "Vehicle Types",
      value: renderVehicleType(),
    },
    {
      label: "Driver Type",
      value: serviceCallData?.pickUpRequestDetails?.requestedServiceDriverType
        ? toTitleCase(
            serviceCallData?.pickUpRequestDetails?.requestedServiceDriverType
          )
        : "N/A",
    },
    {
      label: "Status",
      value: mappedStatuses[serviceCallData?.pickUpStatus],
    },
    {
      label: "Unattended drop-off",
      value: serviceCallData?.pickUpRequestDetails?.unattendedDropOff
        ? "Yes"
        : "No",
    },
    // {
    //   label: "Vehicle ID",
    //   value:
    //     serviceCallData?.driver?.driverRegistrationDetail
    //       ?.driverVehicleInformation?.id,
    // },
    ...(cancelledStatuses.includes(serviceCallData?.pickUpStatus)
      ? [
          {
            label: "Cancelled By",
            value: customerCancelledStatuses.includes(
              serviceCallData?.pickUpStatus
            )
              ? `${serviceCallData?.customer?.firstName} ${serviceCallData?.customer?.lastName}`
              : `${serviceCallData?.driver?.firstName} ${serviceCallData?.driver?.lastName}`,
          },
        ]
      : []),
    {
      label: "Driver Rating",
      value: (
        <Rating
          sx={{ color: "#3fbf5d" }}
          value={
            driverCurrentServiceCallRatingEntry
              ? Number(driverCurrentServiceCallRatingEntry?.rating)
              : 0
          }
          readOnly
        />
      ),
    },
    {
      label: "Updated At",
      value: moment(serviceCallData?.updatedAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
  ];

  const renderFareEstimate = () => {
    const distance = calculateDistance(
      [
        serviceCallData?.pickUpRequestLocationDetails?.originLatitude,
        serviceCallData?.pickUpRequestLocationDetails?.originLongitude,
      ],
      [
        serviceCallData?.pickUpRequestLocationDetails?.destinationLatitude,
        serviceCallData?.pickUpRequestLocationDetails?.destinationLongitude,
      ]
    );

    return calculateFareEstimate(
      billingSettingsData?.baseFee,
      Number(distance),
      billingSettingsData?.perKmRate,
      Number(calculateTimeToDistanceInMinutes(distance)),
      billingSettingsData?.perMinuteRate,
      serviceCallData?.pickUpRequestDetails?.requestedServiceDriverType ===
        ServiceDriverTypes.team,
      serviceCallData?.pickUpRequestDetails?.requestedVehicleClass,
      serviceCallData?.pickUpRequestDetails?.insideThresholdDelivery,
      billingSettingsData?.insideThresholdDeliveryFee
    );
  };

  const paymentSection = [
    ...(inProgressStatuses.includes(serviceCallData?.pickUpStatus)
      ? [
          {
            label: "Estimated Fare",
            value: `$${renderFareEstimate()}`,
          },
        ]
      : [
          {
            label: "Total Fare",
            value: `$${roundToPrecision(
              (serviceCallData?.price +
                serviceCallData?.taxAmountInCents +
                serviceCallData?.tipAmountInCents) /
                100,
              2
            )}`,
          },
        ]),
    {
      label: "Name on Card",
      value:
        serviceCallData?.customer?.defaultPaymentMethod?.nameOnCard ?? "N/A",
    },
    {
      label: "Brand",
      value: serviceCallData?.customer?.defaultPaymentMethod
        ? `${toTitleCase(
            serviceCallData?.customer?.defaultPaymentMethod?.brand
          )} ending in ${
            serviceCallData?.customer?.defaultPaymentMethod?.lastFour
          }`
        : "N/A",
    },
    {
      label: "Expiry date",
      value: serviceCallData?.customer?.defaultPaymentMethod
        ? `${serviceCallData?.customer?.defaultPaymentMethod?.expiryMonth}/${serviceCallData?.customer?.defaultPaymentMethod?.expiryYear}`
        : "N/A",
    },
  ];

  const activitiesSection = [
    {
      primary: "The service call was raised by the customer",
      secondary: `${moment(serviceCallData?.createdAt).format(
        "DD/MM/YYYY h:mm:ssa"
      )} ● Customer`,
      value: serviceCallData?.createdAt,
    },
    ...(serviceCallData?.driver
      ? [
          {
            primary: "The driver accepted the service call",
            secondary: `${moment(
              serviceCallData?.driverAcceptedPickupRequestTimestamp
            ).format("DD/MM/YYYY h:mm:ssa")} ● Driver`,
            value: serviceCallData?.driverAcceptedPickupRequestTimestamp,
          },
        ]
      : []),
    ...(customerCancelledStatuses.includes(serviceCallData?.pickUpStatus)
      ? [
          {
            primary: "The customer cancelled the service call",
            secondary: `${moment(serviceCallData?.updatedAt).format(
              "DD/MM/YYYY h:mm:ssa"
            )} ● Customer`,
            value: serviceCallData?.updatedAt,
          },
        ]
      : []),
    ...(driverCancelStatuses.includes(serviceCallData?.pickUpStatus)
      ? [
          {
            primary: "The driver cancelled the service call",
            secondary: `${moment(serviceCallData?.updatedAt).format(
              "DD/MM/YYYY h:mm:ssa"
            )} ● Driver`,
            value: serviceCallData?.updatedAt,
          },
        ]
      : []),
    {
      primary: "The driver arrived at the pickup point",
      secondary: `${moment(
        serviceCallData?.driverArrivedAtPickupLocationTimestamp
      ).format("DD/MM/YYYY h:mm:ssa")} ● Driver`,
      value: serviceCallData?.driverArrivedAtPickupLocationTimestamp,
    },
    {
      primary: "The driver verified their pickup code",
      secondary: `${moment(
        serviceCallData?.driverVerifiedPickupCodeTimestamp
      ).format("DD/MM/YYYY h:mm:ssa")} ● Driver`,
      value: serviceCallData?.driverVerifiedPickupCodeTimestamp,
    },
    {
      primary: "The driver marked the pickup as in progress",
      secondary: `${moment(
        serviceCallData?.driverMarkedPickupAsInProgressTimestamp
      ).format("DD/MM/YYYY h:mm:ssa")} ● Driver`,
      value: serviceCallData?.driverMarkedPickupAsInProgressTimestamp,
    },
    {
      primary: "The driver arrived at the dropoff location",
      secondary: `${moment(
        serviceCallData?.driverArrivedAtDropOffLocationTimestamp
      ).format("DD/MM/YYYY h:mm:ssa")} ● Driver`,
      value: serviceCallData?.driverArrivedAtDropOffLocationTimestamp,
    },
    {
      primary: "The driver marked the service call as completed",
      secondary: `${moment(
        serviceCallData?.driverCompletedPickupRequestTimestamp
      ).format("DD/MM/YYYY h:mm:ssa")} ● Driver`,
      value: serviceCallData?.driverCompletedPickupRequestTimestamp,
    },
    ...(serviceCallData?.pickUpStatus === PickUpStatus.completed
      ? [
          {
            primary: "The customer marked the service call as complete",
            secondary: `${moment(serviceCallData?.updatedAt).format(
              "DD/MM/YYYY h:mm:ssa"
            )} ● Customer`,
            value: serviceCallData?.updatedAt,
          },
        ]
      : []),
  ].filter((activity, index) =>
    index === 0 ? true : serviceCallData?.createdAt !== activity.value
  );

  const customerCargoImageListSection = [
    {
      label: "Customer Cargo Image",
      value: serviceCallData?.pickUpRequestDetails?.customerCargoUploadOne,
    },
    {
      label: "Customer Cargo Image",
      value: serviceCallData?.pickUpRequestDetails?.customerCargoUploadTwo,
    },
    {
      label: "Customer Cargo Image",
      value: serviceCallData?.pickUpRequestDetails?.customerCargoUploadThree,
    },
  ].filter((image) => image.value);

  const driverCancellationImageListSection = [
    {
      label: "Driver cancellation attachment",
      value:
        serviceCallData?.driverPickUpCancellationFormSubmission?.imageUpload,
    },
  ].filter((image) => image.value);

  const driverDropOffImageListSection = [
    {
      label: "Cargo drop-off photo",
      value: serviceCallData?.driverDropOffUpload,
    },
  ].filter((image) => image.value);

  useEffect(() => {
    const getRoute = async () => {
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: {
          lat: Number(
            serviceCallData?.pickUpRequestLocationDetails?.originLatitude
          ),
          lng: Number(
            serviceCallData?.pickUpRequestLocationDetails?.originLongitude
          ),
        },
        destination: {
          lat: Number(
            serviceCallData?.pickUpRequestLocationDetails?.destinationLatitude
          ),
          lng: Number(
            serviceCallData?.pickUpRequestLocationDetails?.destinationLongitude
          ),
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      setDirectionsResponse(results);
    };

    if (serviceCallData?.pickUpRequestLocationDetails && isLoaded) {
      getRoute();
    }
  }, [serviceCallData?.pickUpRequestLocationDetails, isLoaded]);

  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/service-calls",
            title: "Service Calls",
          },
          {
            url: `/dashboard/service-calls/${serviceCallId}`,
            title: `${serviceCallId}`,
          },
        ]}
        pageTitle={`Service Calls`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/service-calls"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}
        {serviceCallData?.driver ? (
          <ProfilePanel
            accessToken={accessToken}
            isOnline={serviceCallData?.driver?.isOnline}
            cognitoId={serviceCallData?.driver?.cognitoId}
            rating={serviceCallData?.driver?.rating}
            firstName={serviceCallData?.driver?.firstName}
            lastName={serviceCallData?.driver?.lastName}
            avatar={serviceCallData?.driver?.avatar}
            withEdit={false}
            onEditClick={() => {}}
          >
            <>
              <Grid container spacing={2} direction={"column"}>
                {driverPrimarySectionFields.map((field) => (
                  <ProfileListItem label={field.label} value={field.value} />
                ))}
              </Grid>
            </>
          </ProfilePanel>
        ) : (
          <ProfilePanel
            accessToken={accessToken}
            firstName={serviceCallData?.customer?.firstName}
            lastName={serviceCallData?.customer?.lastName}
            avatar={serviceCallData?.customer?.avatar}
            cognitoId={serviceCallData?.customer?.cognitoId}
            rating={serviceCallData?.customer?.rating}
            isOnline={false}
            withEdit={false}
            onEditClick={() => {}}
          >
            <Grid container spacing={2} direction={"column"}>
              {customerPrimarySectionFields.map((field) => (
                <ProfileListItem label={field.label} value={field.value} />
              ))}
            </Grid>
          </ProfilePanel>
        )}

        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Grid item xs={12} pb={3}>
            <Paper sx={{ padding: 2 }}>
              <Grid
                container
                spacing={2}
                direction={"column"}
                justifyContent={"space-evenly"}
              >
                <Grid item>
                  <Typography fontWeight={600} fontSize={16}>
                    Service Call Details
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  paddingBottom={2}
                  justifyContent={"space-between"}
                  spacing={10}
                >
                  <Grid item xs={12} md={6}>
                    <Grid container direction={"column"} spacing={1}>
                      {serviceCallPrimaryListSection.map(
                        ({ label, value }, index) => (
                          <ProfileListItem
                            key={`service-call-details-primary-list-section-${index}`}
                            label={label}
                            value={value}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container direction={"column"} spacing={1}>
                      {serviceCallSecondaryListSection.map(
                        ({ label, value }, index) => (
                          <ProfileListItem
                            key={`service-call-details-secondary-list-section-${index}`}
                            label={label}
                            value={value}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* user avatars, map, and customer payment details */}
          <Grid item xs={12} pb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Paper sx={{ height: "100%" }}>
                  <List>
                    {serviceCallData?.driver && (
                      <>
                        <ListItem>
                          <ListItemAvatar sx={{ paddingRight: 2 }}>
                            <Avatar
                              sx={{ width: 60, height: 60 }}
                              src={serviceCallData?.driver?.avatar}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography sx={{ color: "#00000099" }}>
                                Driver
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="h4"
                                fontWeight={700}
                                fontSize={22}
                              >
                                {`${serviceCallData?.driver?.firstName} ${serviceCallData?.driver?.lastName}`}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </>
                    )}
                    <ListItem>
                      <ListItemAvatar sx={{ paddingRight: 2 }}>
                        <Avatar
                          sx={{ width: 60, height: 60 }}
                          src={serviceCallData?.customer?.avatar}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography sx={{ color: "#00000099" }}>
                            Customer
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="h4"
                            fontWeight={700}
                            fontSize={22}
                          >
                            {`${serviceCallData?.customer?.firstName} ${serviceCallData?.customer?.lastName}`}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Paper sx={{ height: "100%" }}>
                  <GoogleMaps>
                    {serviceCallData?.pickUpRequestLocationDetails
                      ?.originLatitude &&
                      serviceCallData?.pickUpRequestLocationDetails
                        ?.originLongitude && (
                        <Marker
                          title="Pickup"
                          position={{
                            lat: Number(
                              serviceCallData?.pickUpRequestLocationDetails
                                .originLatitude
                            ),
                            lng: Number(
                              serviceCallData?.pickUpRequestLocationDetails
                                .originLongitude
                            ),
                          }}
                        />
                      )}
                    {serviceCallData?.pickUpRequestLocationDetails
                      ?.destinationLatitude &&
                      serviceCallData?.pickUpRequestLocationDetails
                        ?.destinationLongitude && (
                        <Marker
                          title="Dropoff"
                          position={{
                            lat: Number(
                              serviceCallData?.pickUpRequestLocationDetails
                                .destinationLatitude
                            ),
                            lng: Number(
                              serviceCallData?.pickUpRequestLocationDetails
                                .destinationLongitude
                            ),
                          }}
                        />
                      )}
                    {directionsResponse && (
                      <DirectionsRenderer directions={directionsResponse} />
                    )}
                  </GoogleMaps>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Paper sx={{ height: "100%" }}>
                  <Grid item py={1} px={2}>
                    <Typography fontWeight={600} fontSize={16}>
                      Payment Info
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid item py={2} px={2}>
                    <Grid container direction={"column"} spacing={1}>
                      {paymentSection.map(({ label, value }, index) => (
                        <ProfileListItem
                          key={`service-call-details-primary-list-section-${index}`}
                          label={label}
                          value={value}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <ImageModal
            open={imageModalOpen}
            title={imageToLoad.label}
            imageURL={imageToLoad.value}
            handleClose={() => {
              setImageToLoad({ label: "", value: "" });
              setImageModalOpen(false);
            }}
          />
          {/* activities and customer cargo image */}
          <Grid item xs={12} pb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Paper sx={{ height: "100%" }}>
                  <Grid item py={1} px={2}>
                    <Typography fontWeight={600} fontSize={16}>
                      Activities
                    </Typography>
                  </Grid>

                  <Divider />

                  <Grid
                    container
                    direction={"column"}
                    sx={{
                      maxHeight: customerCargoUploadsContainerHeight,
                      position: "relative",
                      overflow: "auto",
                    }}
                  >
                    <List disablePadding>
                      {activitiesSection.map(
                        ({ primary, secondary }, index) => (
                          <>
                            <ListItem>
                              <ListItemText
                                primaryTypographyProps={{
                                  sx: {
                                    color: "#0070F2",
                                  },
                                }}
                                primary={primary}
                                secondary={secondary}
                              />
                            </ListItem>
                            {index !== activitiesSection.length - 1 && (
                              <Divider />
                            )}
                          </>
                        )
                      )}
                    </List>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Paper sx={{ height: "100%" }}>
                  <Grid item py={1} px={2}>
                    <Typography fontWeight={600} fontSize={16}>
                      Picture of cargo taken by customer
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    item
                    justifyContent={"flex-start"}
                    alignContent={"center"}
                    px={2}
                    ref={customerCargoUploadsRef}
                  >
                    {customerCargoImageListSection.length > 0 ? (
                      <Grid item xs={12}>
                        <ImageList cols={4}>
                          {customerCargoImageListSection.map((item, index) => (
                            <ImageListItem
                              onClick={() => {
                                setImageToLoad(item);
                                setImageModalOpen(true);
                              }}
                              sx={{ height: 185, cursor: "pointer" }}
                              key={`customer-cargo-photo-list-section-${index}`}
                            >
                              <img
                                srcSet={`${item.value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.value}?w=164&h=164&fit=crop&auto=format`}
                                loading="lazy"
                                aria-label={item?.label}
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent={"center"}
                          alignContent={"center"}
                          alignItems={"center"}
                        >
                          <Grid item p={7}>
                            <Typography sx={{ color: "#626262" }}>
                              No images found
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
              {serviceCallData?.driverPickUpCancellationFormSubmission
                ?.imageUpload && (
                <Grid item xs={12}>
                  <Paper>
                    <Grid item py={1} px={2}>
                      <Typography fontWeight={600} fontSize={16}>
                        Driver cancellation form attachments
                      </Typography>
                    </Grid>
                    <Divider />
                    <Grid
                      container
                      item
                      justifyContent={"flex-start"}
                      alignContent={"center"}
                      px={2}
                    >
                      {driverCancellationImageListSection.length > 0 ? (
                        <Grid item xs={12}>
                          <ImageList cols={4}>
                            {driverCancellationImageListSection.map(
                              (item, index) => (
                                <ImageListItem
                                  onClick={() => {
                                    setImageToLoad(item);
                                    setImageModalOpen(true);
                                  }}
                                  sx={{ height: 185, cursor: "pointer" }}
                                  key={`driver-cancellation-photo-list-section-${index}`}
                                >
                                  <img
                                    srcSet={`${item.value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.value}?w=164&h=164&fit=crop&auto=format`}
                                    loading="lazy"
                                    aria-label={item.label}
                                  />
                                </ImageListItem>
                              )
                            )}
                          </ImageList>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Grid
                            container
                            justifyContent={"center"}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item p={6.5}>
                              <Typography sx={{ color: "#626262" }}>
                                No image found
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* dropoff picture */}
          <Grid item xs={12} pb={3}>
            <Paper>
              <Grid item py={1} px={2}>
                <Typography fontWeight={600} fontSize={16}>
                  Picture of cargo at drop-off point
                </Typography>
              </Grid>
              <Divider />
              <Grid
                container
                item
                justifyContent={"flex-start"}
                alignContent={"center"}
                px={2}
              >
                {driverDropOffImageListSection.length > 0 ? (
                  <Grid item xs={12}>
                    <ImageList cols={4}>
                      {driverDropOffImageListSection.map((item, index) => (
                        <ImageListItem
                          onClick={() => {
                            setImageToLoad(item);
                            setImageModalOpen(true);
                          }}
                          sx={{ height: 185, cursor: "pointer" }}
                          key={`driver-drop-off-photo-list-section-${index}`}
                        >
                          <img
                            srcSet={`${item.value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.value}?w=164&h=164&fit=crop&auto=format`}
                            loading="lazy"
                            aria-label={item.label}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item p={6.5}>
                        <Typography sx={{ color: "#626262" }}>
                          No image found
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ServiceCallDetails;
