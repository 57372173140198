import moment from "moment";
import { useEffect, useRef, useState } from "react";

const useCSVDownloadState = () => {
  const [csvDownloadLoading, setCsvDownloadLoading] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [csvFileName, setCsvFilename] = useState("");
  const [shouldDownload, setShouldDownload] = useState(false);
  const csvLinkRef = useRef<any>(null);

  useEffect(() => {
    if (
      csvData &&
      !csvDownloadLoading &&
      csvLinkRef?.current &&
      shouldDownload
    ) {
      csvLinkRef.current.download = `${csvFileName}`;
      csvLinkRef?.current?.click();
      setShouldDownload(false);
    }
  }, [csvData, csvDownloadLoading, csvLinkRef, csvFileName]);

  return {
    csvDownloadLoading,
    setCsvDownloadLoading,
    csvData,
    setCsvData,
    shouldDownload,
    setShouldDownload,
    setCsvFilename,
    csvLinkRef,
  };
};

export default useCSVDownloadState;
