import {
  Alert,
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ProfileListItem from "./ProfileListItem";
import { ReactElement, useState } from "react";
import Modal from "../Modal/Modal";
import { approveDriverProfileChanges } from "../../hooks/useDrivers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { unCamelCase } from "../../helpers/formatters";
const ProfilePanel = ({
  accessToken,
  firstName,
  lastName,
  pendingChanges,
  avatar,
  cognitoId,
  isOnline,
  rating,
  children,
  withEdit = true,
  onEditClick,
}: {
  accessToken: string;
  firstName: string;
  lastName: string;
  avatar: string;
  pendingChanges?: any;
  cognitoId: string;
  isOnline: boolean;
  rating: string;
  withEdit?: boolean;
  onEditClick: () => void;
  children: ReactElement;
}) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const showPendingChangesModal = () => {

  // }

  const {
    mutate: handleDriverProfileChangeApproval,
    isLoading: isDriverProfileChangeApproveLoading,
  } = useMutation({
    mutationFn: approveDriverProfileChanges,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.cancelQueries({
        queryKey: ["driver", cognitoId],
      });
      queryClient.setQueryData(["driver", cognitoId], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
    },
  });

  const handleApproveChangesSubmit = () => {
    handleDriverProfileChangeApproval({ accessToken, cognitoId });
  };

  return (
    <Grid item xs={12} lg={4}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Driver profile update successful
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
          setErrorMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
            setErrorMessage("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Modal
        loading={isDriverProfileChangeApproveLoading}
        title={`${firstName} ${lastName} has changes pending approval`}
        open={open}
        onSubmit={handleApproveChangesSubmit}
        onCancel={() => {
          setOpen(false);
        }}
        handleClose={() => {
          // if (!isDriverProfileUpdateLoading) {
          setOpen(false);
          // }
        }}
        submitButtonLabel="Yes"
      >
        <Grid>
          <Typography>
            {firstName} {lastName} has the following changes to their profile
            that are pending approval:
          </Typography>
          <List>
            {pendingChanges &&
              Object.keys(pendingChanges).map((change: any) => (
                <ListItem>
                  <ListItemText
                    primary={unCamelCase(change)}
                    secondary={
                      change === "avatar" ? (
                        <Link href={pendingChanges[change]}>
                          {pendingChanges[change]}
                        </Link>
                      ) : (
                        <>{pendingChanges[change]}</>
                      )
                    }
                  />
                </ListItem>
              ))}
          </List>
          <Typography>Do you approve these changes?</Typography>
        </Grid>
      </Modal>
      <Paper sx={{ padding: 2, position: "relative" }}>
        {withEdit && (
          <IconButton
            sx={{ position: "absolute", top: "15px", right: "15px" }}
            color="primary"
            onClick={onEditClick}
          >
            <EditOutlinedIcon sx={{ color: "#28a1fa" }} />
          </IconButton>
        )}
        <Grid container spacing={2}>
          {/* avatar and username */}
          <Grid item>
            {pendingChanges?.avatar ? (
              <Badge
                badgeContent={"!"}
                color="error"
                onClick={(e) => {
                  setOpen(true);
                }}
                sx={{ cursor: "pointer" }}
              >
                <Avatar sx={{ width: 80, height: 80 }} src={avatar} />
              </Badge>
            ) : (
              <Avatar sx={{ width: 80, height: 80 }} src={avatar} />
            )}
          </Grid>
          <Grid container item xs={9} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={700} fontSize={22}>
                {firstName} {lastName}
              </Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: "#610095",
                  padding: "4px 8px",
                  borderRadius: "3px",
                }}
              >
                <Typography sx={{ color: "white" }} fontSize={14}>
                  {cognitoId}
                </Typography>
              </Box>
            </Grid>
            {isOnline && (
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "#3fbf5d",
                    padding: "4px 8px",
                    borderRadius: "3px",
                  }}
                >
                  <Typography sx={{ color: "white" }} fontSize={14}>
                    Online
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item>
              <Grid
                container
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
                sx={{ padding: "4px 8px" }}
              >
                <Grid item>
                  <StarIcon sx={{ color: "#3fbf5d" }} />
                </Grid>
                <Grid item>
                  <Typography>{rating}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        {children}
      </Paper>
    </Grid>
  );
};

export default ProfilePanel;
