import { Grid, TextField } from "@mui/material";
import useFormFieldsBlurState from "../../../../../hooks/useFormFieldsBlurState";
import CreditCardNumberTextField from "../../../../../components/CreditCardNumberTextField/CreditCardNumberTextField";
import { DateField, DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  validatePostalCode,
  validatePresence,
  validateCardNumber,
} from "../../../../../helpers/validationHelpers";
import CreditCardCCVTextField from "../../../../../components/CreditCardCCVTextField/CreditCardCCVTextField";
import ErrorHelperText from "../../../../../components/ErrorHelperText/ErrorHelperText";
import moment from "moment";

const AddCreditCardPage = ({
  nameOnCard,
  setNameOnCard,
  cardNumber,
  setCardNumber,
  postalCode,
  setPostalCode,
  expiryDate,
  setExpiryDate,
  ccv,
  setCcv,
}: {
  nameOnCard: any;
  setNameOnCard: any;
  cardNumber: any;
  setCardNumber: any;
  postalCode: any;
  setPostalCode: any;
  expiryDate: any;
  setExpiryDate: any;
  ccv: any;
  setCcv: any;
}) => {
  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      nameOnCard,
      cardNumber,
      postalCode,
      expiryDate,
      ccv,
    });

  const currentDate = moment();

  console.log("expiry date", expiryDate);
  const formErrors = {
    nameOnCard: {
      empty:
        blurredFormFields.nameOnCard &&
        !validatePresence(nameOnCard) &&
        "Please enter your name as it appears on your credit card",
    },
    cardNumber: {
      empty:
        blurredFormFields.cardNumber &&
        !validatePresence(cardNumber) &&
        "Please enter your credit card number",
      invalid:
        blurredFormFields?.cardNumber &&
        cardNumber.length > 0 &&
        !validateCardNumber(cardNumber) &&
        "Please enter a valid credit card number",
    },
    postalCode: {
      empty:
        blurredFormFields?.postalCode &&
        !validatePresence(postalCode) &&
        "Please enter the postal code associated with your credit card",
      invalid:
        blurredFormFields?.postalCode &&
        postalCode.length > 0 &&
        !validatePostalCode(postalCode) &&
        "Please enter a valid postal code",
    },
    expiryDate: {
      invalid:
        blurredFormFields?.expiryDate &&
        !moment(expiryDate, "MM/YY", true).isValid() &&
        "Invalid date",
      minDate:
        blurredFormFields?.expiryDate &&
        moment(expiryDate, "MM/YY", true).isValid() &&
        !moment(expiryDate, "MM/YY", true).isAfter(currentDate) &&
        "Date must be in the future",
    },
    ccv: {
      empty:
        blurredFormFields?.ccv &&
        !validatePresence(ccv) &&
        "Please enter your credit card's CCV",
      invalid:
        blurredFormFields.ccv && ccv.length < 3 && "Please enter a valid CCV",
    },
  };

  const nameOnCardErrors: any = filterObjectByValues(
    formErrors?.nameOnCard,
    (key, value) => value
  );

  const cardNumberErrors: any = filterObjectByValues(
    formErrors?.cardNumber,
    (key, value) => value
  );

  const postalCodeErrors: any = filterObjectByValues(
    formErrors?.postalCode,
    (key, value) => value
  );

  const expiryDateErrors: any = filterObjectByValues(
    formErrors?.expiryDate,
    (key, value) => value
  );

  const ccvErrors: any = filterObjectByValues(
    formErrors?.ccv,
    (key, value) => value
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            error={objectHasKeys(nameOnCardErrors)}
            fullWidth
            placeholder="Name On Card"
            label={"Name On Card"}
            value={nameOnCard}
            onFocus={() => {
              setFormFieldToFocused("nameOnCard");
            }}
            onChange={(e) => {
              setNameOnCard(e.target.value);
            }}
            onBlur={() => {
              setFormFieldToBlurred("nameOnCard");
            }}
            variant="outlined"
          />
          {mapObjectValues(nameOnCardErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`credit-card-form-name-on-card-errors-${index}`}
              errorText={nameOnCardErrors[key]}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={objectHasKeys(postalCodeErrors)}
            fullWidth
            placeholder="Postal Code"
            label={"Postal Code"}
            value={postalCode}
            onFocus={() => {
              setFormFieldToFocused("postalCode");
            }}
            onChange={(e) => {
              setPostalCode(e.target.value);
            }}
            onBlur={() => {
              setFormFieldToBlurred("postalCode");
            }}
            variant="outlined"
          />
          {mapObjectValues(postalCodeErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`credit-card-form-postal-code-errors-${index}`}
              errorText={postalCodeErrors[key]}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <CreditCardNumberTextField
            error={objectHasKeys(cardNumberErrors)}
            placeholder="Card Number"
            label={"Card Number"}
            value={cardNumber}
            onFocus={() => {
              setFormFieldToFocused("cardNumber");
            }}
            setValue={setCardNumber}
            onBlur={() => {
              setFormFieldToBlurred("cardNumber");
            }}
          />
          {mapObjectValues(cardNumberErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`credit-card-form-card-number-errors-${index}`}
              errorText={cardNumberErrors[key]}
            />
          ))}
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <DateField
              fullWidth
              label="Expiry date"
              value={expiryDate}
              onChange={(newValue) => {
                setExpiryDate(newValue);
              }}
              format="MM/YY"
              variant="outlined"
              onBlur={() => {
                setFormFieldToBlurred("expiryDate");
              }}
              onFocus={() => {
                setFormFieldToFocused("expiryDate");
              }}
              slotProps={{
                textField: {
                  error: objectHasKeys(expiryDateErrors),
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CreditCardCCVTextField
              error={objectHasKeys(ccvErrors)}
              value={ccv}
              setValue={setCcv}
              onFocus={() => {
                setFormFieldToFocused("ccv");
              }}
              onBlur={() => {
                setFormFieldToBlurred("ccv");
              }}
            />
          </Grid>
          <Grid item>
            {mapObjectValues(expiryDateErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`credit-card-form-expiry-date-errors-${index}`}
                errorText={expiryDateErrors[key]}
              />
            ))}
            {mapObjectValues(ccvErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`credit-card-form-ccv-errors-${index}`}
                errorText={ccvErrors[key]}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default AddCreditCardPage;
