import { CloudUpload } from "@mui/icons-material";
import {
  Alert,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";

const ImageUploadSection = ({
  uploadedFile = null,
  loading = false,
  handleUpload,
  label = "Upload",
}: {
  uploadedFile: any;
  loading?: boolean;
  handleUpload: any;
  label?: string;
}) => {
  const [showError, setShowError] = useState(false);

  const inputFile = useRef<any>(null);

  const isValidFileUploaded = (file: any) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target?.files?.length < 1) {
      return;
    }

    if (isValidFileUploaded(e.target.files[0])) {
      handleUpload(e.target.files[0]);
    } else {
      setShowError(true);
    }
  };

  const handleContentRender = () => {
    if (loading) {
      return (
        <Grid item>
          <CircularProgress size={60} sx={{ color: "rgb(242, 104, 34)" }} />
        </Grid>
      );
    } else if (!loading && uploadedFile) {
      return (
        <Grid item>
          <img style={{ width: "100%", height: "100%" }} src={uploadedFile} />
        </Grid>
      );
    } else {
      return (
        <>
          <Grid item>
            <CloudUpload sx={{ fontSize: 80 }} />
          </Grid>
          <Grid item>
            <Typography>{label}</Typography>
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => {
          setShowError(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Invalid file type. Please upload either a .PNG or .JPEG file
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Grid
          onClick={() => {
            if (!loading) {
              inputFile.current.click();
            }
          }}
          container
          item
          xs={12}
          direction={"column"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          sx={{
            borderStyle: "dashed",
            borderWidth: "1.5px",
            borderColor: "rgba(0, 0, 0, 0.23)",
            py: 4,
            // mb: 4,
            cursor: loading ? "not-allowed" : "pointer",
          }}
        >
          {handleContentRender()}
        </Grid>
        <input
          type="file"
          id="file"
          accept="image/png, image/jpeg"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Grid>
    </>
  );
};

export default ImageUploadSection;
