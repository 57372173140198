import { Box, Button, Grid, Typography } from "@mui/material";
import "../../../assets/css/icon-fonts.css";
import { NavLink, useLocation } from "react-router-dom";

const NavBarPageButton = ({ label, iconName, linkTo, icon = null }: any) => {
  const { pathname: currentPathname } = useLocation();
  return (
    <Box
      component={"li"}
      sx={{
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        paddingTop: "21px",
        paddingBottom: "21px",
        width: { xs: "100vw", md: "auto" },
        background: currentPathname === linkTo ? "#fffbf8" : "#fff",
        borderBottom: currentPathname === linkTo ? "2px solid #f26822" : "none",
      }}
    >
      {iconName ? (
        <NavLink
          to={linkTo}
          style={({ isActive, isPending }) => {
            return {
              fontSize: "14px",
              // height: "100%",

              // paddingTop: "20px",
              // paddingBottom: "20px",
              //   borderBottom: isActive ? "2px solid #f26822" : "none",
              color: isActive ? "#f26822" : "#6D6D6D",
            };
          }}
        >
          {iconName && (
            <span
              className={`icon-${iconName}`}
              style={{ marginRight: "10px" }}
            ></span>
          )}
          {icon}
          {label}
        </NavLink>
      ) : (
        <NavLink
          to={linkTo}
          style={({ isActive, isPending }) => {
            return {
              fontSize: "14px",
              // height: "100%",

              // paddingTop: "20px",
              // paddingBottom: "20px",
              //   borderBottom: isActive ? "2px solid #f26822" : "none",
              color: isActive ? "#f26822" : "#6D6D6D",
            };
          }}
        >
          <Grid container item alignContent={"center"} alignItems={"center"}>
            <Grid item>{icon}</Grid>
            <Grid item>{label}</Grid>
          </Grid>
        </NavLink>
      )}
    </Box>
  );
};

export default NavBarPageButton;
