import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getCompanyBillingSettings = async (
  accessToken: string,
  companyId: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/billing-settings`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log("error", error);
  }
};

const useCompanyBillingSettings = (accessToken: string, companyId: string) => {
  return useQuery({
    queryKey: ["company-billing-settings", companyId],
    queryFn: () => getCompanyBillingSettings(accessToken, companyId),
  });
};

export { useCompanyBillingSettings, getCompanyBillingSettings };
