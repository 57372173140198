import { useEffect, useRef, useState } from "react";

const useElementSizeObserver = () => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (elementRef?.current?.offsetWidth !== width) {
        setWidth(elementRef?.current?.offsetWidth);
      }
      if (elementRef.current.offsetHeight !== height) {
        setHeight(elementRef.current.offsetHeight);
      }
    });

    resizeObserver.observe(elementRef.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [elementRef.current]);

  return { width, height, elementRef };
};

export default useElementSizeObserver;
