import "../../../assets/css/icon-fonts.css";
import { Box, Grid, Typography } from "@mui/material";

interface Props {
  label: string;
  statValue?: string | number;
  iconName: string;
  iconBackground: string;
  borderColor: string;
}

const DashboardStatCard = ({
  label,
  statValue,
  iconName,
  iconBackground,
  borderColor,
}: Props) => {
  return (
    <Box
      sx={{
        borderTop: `3px solid ${borderColor}`,
        borderRadius: "5px",
        backgroundColor: "white",
        width: { xs: "100%", sm: "40%", md: "17%", lg: "15%" },
        padding: "10px 20px",
        marginBottom: "20px",
      }}
    >
      <Grid container justifyContent={"space-between"} flexWrap={"nowrap"}>
        <Grid item>
          <Typography>{label}</Typography>
          <Typography variant={"h4"}>{statValue}</Typography>
        </Grid>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: "66px",
              height: "66px",
              fontSize: "24px",
              color: "white",
              background: iconBackground,
            }}
          >
            <Box>
              <span className={`icon-${iconName}`}></span>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardStatCard;
