import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  isAnyKeyValueTrue,
  validatePresence,
} from "../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCustomerProfile } from "../../../hooks/useCustomers";
import { updateCompanyProfile } from "../../../hooks/useCompanies";
import ImageUploadSection from "../../../components/ImageUploadSection/ImageUploadSection";
import { uploadAssetToCloudinary } from "../../../utils/cloudinary";

const CompanyProfileEditModal = ({
  companyId,
  accessToken,
  open,
  setOpen,
  companyName,
  companyDescription,
  companyAddressLineOne,
  companyAddressLineTwo,
  companyCity,
  companyProvince,
  companyCountry,
  companyLogo,
}: any) => {
  const queryClient = useQueryClient();

  const [name, setName] = useState(companyName || "");
  const [description, setDescription] = useState(companyDescription || "");
  const [addressLineOne, setAddressLineOne] = useState(
    companyAddressLineOne || ""
  );
  const [addressLineTwo, setAddressLineTwo] = useState(
    companyAddressLineTwo || ""
  );
  const [city, setCity] = useState(companyCity || "");
  const [province, setProvince] = useState(companyProvince || "");
  const [logo, setLogo] = useState(companyLogo || "");
  const [companyLogoUploadLoading, setCompanyLogoUploadLoading] =
    useState(false);
  const [country, setCountry] = useState(companyCountry || "");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (open) {
      setName(companyName);
      setDescription(companyDescription);
      setAddressLineOne(companyAddressLineOne);
      setAddressLineTwo(companyAddressLineTwo);
      setCity(companyCity);
      setProvince(companyProvince);
      setCountry(companyCountry);
      setLogo(companyLogo);
    }
  }, [open]);

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      name,
      description,
    });

  const formErrors = {
    name: {
      error:
        blurredFormFields.name &&
        !validatePresence(name) &&
        "Please enter a company name",
    },
    description: {
      error:
        blurredFormFields.description &&
        !validatePresence(description) &&
        "Please enter a company name",
      descriptionTooLong:
        blurredFormFields.description &&
        validatePresence(description) &&
        description.length > 250 &&
        "Description must be less than 250 characters long",
    },
  };

  const submitButtonDisabled = [formErrors.name, formErrors.description].some(
    (value) => isAnyKeyValueTrue(value)
  );

  const {
    mutate: handleCompanyProfileUpdate,
    isLoading: isCompanyProfileUpdateLoading,
  } = useMutation({
    mutationFn: updateCompanyProfile,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.cancelQueries({
        queryKey: ["company", companyId],
      });
      queryClient.setQueryData(["company", companyId], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
      // setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    setShowError(false);
    setErrorMessage("");
    handleCompanyProfileUpdate({
      companyId,
      accessToken,
      companyFields: {
        name,
        description,
        companyLogo: logo,
        addressLineOne,
        addressLineTwo,
        city,
        province,
        country,
      },
    });
  };

  const handleCompanyLogoUpload = async (file: any) => {
    setCompanyLogoUploadLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, "company-logos");
      setLogo(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setCompanyLogoUploadLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      loading={isCompanyProfileUpdateLoading}
      onSubmit={handleSubmit}
      onCancel={() => {
        setOpen(false);
      }}
      handleClose={() => {
        if (!isCompanyProfileUpdateLoading) {
          setOpen(false);
        }
      }}
      submitButtonLabel="Update"
      title="Company Details"
      submitButtonDisabled={submitButtonDisabled}
    >
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowSuccess(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Company update successful
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
            setErrorMessage("");
          }}
        >
          <Alert
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Company Name"}
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("name");
              }}
              onBlur={() => {
                setFormFieldToBlurred("name");
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.name))}
              helperText={isAnyKeyValueTrue(formErrors.name)}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Description"}
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("description");
              }}
              onBlur={() => {
                setFormFieldToBlurred("description");
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.description))}
              helperText={isAnyKeyValueTrue(formErrors.description)}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"Address line #1"}
              fullWidth
              value={addressLineOne}
              onChange={(e) => {
                setAddressLineOne(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Address line #2"}
              fullWidth
              value={addressLineTwo}
              onChange={(e) => {
                setAddressLineTwo(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"City"}
              fullWidth
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Province"}
              fullWidth
              value={province}
              onChange={(e) => {
                setProvince(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Country"}
              fullWidth
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography>Company Logo:</Typography>
          </Grid>
          <ImageUploadSection
            uploadedFile={logo}
            loading={companyLogoUploadLoading}
            label={"Upload company logo"}
            handleUpload={handleCompanyLogoUpload}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CompanyProfileEditModal;
