import {
  Alert,
  Grid,
  ImageList,
  ImageListItem,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import ImageModal from "../../../components/ImageModal/ImageModal";
import { toggleDriverApprovalStatus } from "../../../hooks/useDrivers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const DocumentsTab = ({
  driverRegistrationDetail,
  cognitoId,
  accessToken,
}: any) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageToLoad, setImageToLoad] = useState({ label: "", value: "" });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const imageListSection = [
    {
      label: "Driving License (Front)",
      value: driverRegistrationDetail?.drivingLicenseFrontUpload,
    },
    {
      label: "Driving License (Back)",
      value: driverRegistrationDetail?.drivingLicenseBackUpload,
    },
    {
      label: "Vehicle Side",
      value:
        driverRegistrationDetail?.driverVehicleInformation?.vehicleSideUpload,
    },
    {
      label: "Vehicle Cargo Bed",
      value:
        driverRegistrationDetail?.driverVehicleInformation
          ?.vehicleCargoBedUpload,
    },
    {
      label: "Vehicle Proof of Ownership",
      value:
        driverRegistrationDetail?.driverVehicleInformation
          ?.vehicleProofOfOwnershipUpload,
    },
    {
      label: "Vehicle Insurance",
      value:
        driverRegistrationDetail?.driverVehicleInformation
          ?.vehicleInsuranceUpload,
    },
  ].filter((image) => image.value);

  console.log("documentsTab", imageListSection);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setChecked(driverRegistrationDetail?.driverEvaluationCompleted);
  }, [driverRegistrationDetail?.driverEvaluationCompleted]);

  const {
    mutate: handleDriverApprovalStatusToggle,
    isLoading: isDriverProfileUpdateLoading,
  } = useMutation({
    mutationFn: toggleDriverApprovalStatus,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.cancelQueries({
        queryKey: ["driver", cognitoId],
      });
      queryClient.setQueryData(["driver", cognitoId], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });

      setOpen(false);
      setChecked((prev) => !prev);
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
    },
  });

  const handleSubmit = () => {
    setShowError(false);
    setErrorMessage("");
    handleDriverApprovalStatusToggle({
      accessToken,
      cognitoId,
      driverEvaluationCompleted:
        !driverRegistrationDetail?.driverEvaluationCompleted,
    });
  };

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      rowSpacing={2}
      container
      direction={"column"}
      justifyContent={"space-evenly"}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Driver approval status updated
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
          setErrorMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
            setErrorMessage("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item>
        <Typography fontWeight={600} fontSize={16}>
          Status:{" "}
          {driverRegistrationDetail?.driverEvaluationCompleted ? (
            <Typography
              fontWeight={600}
              fontSize={16}
              component="span"
              sx={{ color: "#3fbf5d" }}
            >
              Approved
            </Typography>
          ) : (
            <Typography
              fontWeight={600}
              fontSize={16}
              component="span"
              sx={{ color: "#1162FB" }}
            >
              Pending Approval
            </Typography>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={600} fontSize={16}>
          Documents
        </Typography>
      </Grid>
      <ImageModal
        open={imageModalOpen}
        title={imageToLoad.label}
        imageURL={imageToLoad.value}
        handleClose={() => {
          setImageToLoad({ label: "", value: "" });
          setImageModalOpen(false);
        }}
      />
      <Grid container item justifyContent={"flex-start"}>
        <Grid item xs={12}>
          <ImageList cols={imageListSection?.length}>
            {imageListSection.map((item, index) => (
              <ImageListItem
                onClick={() => {
                  setImageToLoad(item);
                  setImageModalOpen(true);
                }}
                sx={{ height: 185, cursor: "pointer" }}
                key={`vehicle-information-vehicle-photo-list-section-${index}`}
              >
                <img
                  srcSet={`${item.value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.value}?w=164&h=164&fit=crop&auto=format`}
                  loading="lazy"
                  aria-label={item.label}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
      <Modal
        loading={isDriverProfileUpdateLoading}
        title={
          checked
            ? `Change user to "Pending Approval"?`
            : `Change user to "Approved"?`
        }
        open={open}
        onSubmit={handleSubmit}
        onCancel={() => {
          setOpen(false);
        }}
        handleClose={() => {
          if (!isDriverProfileUpdateLoading) {
            setOpen(false);
          }
        }}
        submitButtonLabel="Yes"
      >
        <Grid>
          <Typography>
            Are you sure you want to toggle this user's status to{" "}
            {checked ? `"Pending Approval"?` : `"Approved"?`}
          </Typography>
        </Grid>
      </Modal>
      <Grid
        container
        item
        justifyContent={"flex-end"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Grid item>
          <Typography fontWeight={checked ? 400 : 700}>
            Pending Approval
          </Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={checked}
            onClick={() => {
              console.log("tried switching");
              setOpen(true);
            }}
          />
        </Grid>
        <Grid item>
          <Typography fontWeight={checked ? 700 : 400}>Approved</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentsTab;
