import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  isAnyKeyValueTrue,
  validateDrivingLicenseNumber,
  validateEmail,
  validatePhoneNumber,
  validatePostalCode,
  validatePresence,
} from "../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import cities from "../../../utils/cities.json";
import provinces from "../../../utils/provinces.json";
import { updateDriverProfile } from "../../../hooks/useDrivers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const DriverProfileEditModal = ({
  accessToken,
  cognitoId,
  open,
  setOpen,
  driverFirstName,
  driverLastName,
  driverPhoneNumber,
  driverEmail,
  driverServiceTutorialCompleted,
  driverCriminalRecordSubmitted,
  driverCriminalRecordCheckCompleted,
  driverIsOnline,
  driverServiceCallAcceptanceRate,
  driverServiceCallCancellationRate,
  driverAddress,
  driverCity,
  driverPreferredCity,
  driverProvince,
  driverPostalCode,
  driverCountry,
  driverDrivingLicenseNumber,
}: any) => {
  const queryClient = useQueryClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [firstName, setFirstName] = useState(driverFirstName || "");
  const [lastName, setLastName] = useState(driverLastName || "");
  const [phoneNumber, setPhoneNumber] = useState(driverPhoneNumber || "");
  const [email, setEmail] = useState(driverEmail || "");
  const [driverEvaluationCompleted, setDriverEvaluationCompleted] = useState(
    driverServiceTutorialCompleted || false
  );
  const [criminalRecordSubmitted, setCriminalRecordSubmitted] = useState(
    driverCriminalRecordSubmitted || false
  );
  const [criminalRecordCheckCompleted, setCriminalRecordCheckCompleted] =
    useState(driverCriminalRecordCheckCompleted || false);
  const [isOnline, setIsOnline] = useState(driverIsOnline || false);
  const [driverAcceptanceRate, setDriverAcceptanceRate] = useState(
    driverServiceCallAcceptanceRate || 0
  );
  const [driverCancellationRate, setDriverCancellationRate] = useState(
    driverServiceCallCancellationRate || 0
  );
  const [address, setAddress] = useState(driverAddress || "");
  const [city, setCity] = useState(driverCity || "");
  const [preferredCity, setPreferredCity] = useState(driverPreferredCity || "");
  const [province, setProvince] = useState(driverProvince || "");
  const [postalCode, setPostalCode] = useState(driverPostalCode || "");
  const [country, setCountry] = useState(driverCountry || "");
  const [drivingLicenseNumber, setDrivingLicenseNumber] = useState(
    driverDrivingLicenseNumber || ""
  );

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      firstName,
      lastName,
      address,
      city,
      postalCode,
      country,
      drivingLicenseNumber,
    });

  useEffect(() => {
    if (open) {
      setFirstName(driverFirstName);
      setLastName(driverLastName);
      setPhoneNumber(driverPhoneNumber);
      setEmail(driverEmail);
      setDriverEvaluationCompleted(driverServiceTutorialCompleted);
      setCriminalRecordSubmitted(driverCriminalRecordSubmitted);
      setCriminalRecordCheckCompleted(driverCriminalRecordCheckCompleted);
      setIsOnline(driverIsOnline);
      setDriverAcceptanceRate(driverServiceCallAcceptanceRate);
      setDriverCancellationRate(driverServiceCallCancellationRate);
      setAddress(driverAddress);
      setCity(driverCity);
      setPreferredCity(driverPreferredCity);
      setProvince(driverProvince);
      setPostalCode(driverPostalCode);
      setCountry(driverCountry);
      setDrivingLicenseNumber(driverDrivingLicenseNumber);
    }
  }, [open]);

  const formErrors = {
    firstName: {
      error:
        blurredFormFields.firstName &&
        !validatePresence(firstName) &&
        "Please enter a first name",
    },
    lastName: {
      error:
        blurredFormFields.lastName &&
        !validatePresence(lastName) &&
        "Please enter a last name",
    },
    address: {
      error:
        blurredFormFields.address &&
        !validatePresence(address) &&
        "Please enter an address",
    },
    city: {
      error:
        blurredFormFields.city &&
        !validatePresence(city) &&
        "Please enter a city",
    },
    postalCode: {
      error:
        blurredFormFields.postalCode &&
        postalCode &&
        !validatePostalCode(postalCode) &&
        "Invalid postal code",
    },
    drivingLicenseNumber: {
      error:
        blurredFormFields.drivingLicenseNumber &&
        drivingLicenseNumber.length > 0 &&
        !validateDrivingLicenseNumber(drivingLicenseNumber) &&
        "Kindly enter the driver license number without any spaces or special characters",
    },
    // phoneNumber: {
    //   error:
    //     blurredFormFields?.phoneNumber &&
    //     phoneNumber.length > 0 &&
    //     !validatePhoneNumber(phoneNumber),
    //   message: "Phone number is invalid!",
    // },
    // email: {
    //   error:
    //     blurredFormFields?.email && email.length > 0 && !validateEmail(email),
    //   message: "Email is invalid!",
    // },
  };

  const submitButtonDisabled = [
    formErrors.firstName,
    formErrors.lastName,
    formErrors.address,
    formErrors.city,
    formErrors.postalCode,
    formErrors.drivingLicenseNumber,
  ].some((value) => isAnyKeyValueTrue(value));

  const {
    mutate: handleDriverProfileUpdate,
    isLoading: isDriverProfileUpdateLoading,
  } = useMutation({
    mutationFn: updateDriverProfile,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.cancelQueries({
        queryKey: ["driver", cognitoId],
      });
      queryClient.setQueryData(["driver", cognitoId], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
      // setSubmitError(error);
    },
  });

  const handleSubmit = async () => {
    setShowError(false);
    setErrorMessage("");
    handleDriverProfileUpdate({
      accessToken,
      cognitoId,
      driverFields: {
        firstName,
        lastName,
        driverEvaluationCompleted,
        criminalRecordSubmitted,
        criminalRecordCheckCompleted,
        address,
        city,
        preferredCity,
        province,
        postalCode,
        drivingLicenseNumber,
      },
    });
  };

  return (
    <Modal
      open={open}
      loading={isDriverProfileUpdateLoading}
      onSubmit={handleSubmit}
      onCancel={() => {
        setOpen(false);
      }}
      handleClose={() => {
        if (!isDriverProfileUpdateLoading) {
          setOpen(false);
        }
      }}
      submitButtonLabel="Update"
      title="Profile Details"
      submitButtonDisabled={submitButtonDisabled}
    >
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowSuccess(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Driver profile update successful
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
            setErrorMessage("");
          }}
        >
          <Alert
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"First Name"}
              fullWidth
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.firstName))}
              helperText={isAnyKeyValueTrue(formErrors.firstName)}
              onFocus={() => {
                setFormFieldToFocused("firstName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("firstName");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Last Name"}
              fullWidth
              value={lastName}
              error={Boolean(isAnyKeyValueTrue(formErrors.lastName))}
              helperText={isAnyKeyValueTrue(formErrors.lastName)}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("lastName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("lastName");
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled
              label={"Phone Number"}
              fullWidth
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              label={"Email"}
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Service Tutorial Completed</InputLabel>
              <Select
                value={Number(driverEvaluationCompleted)}
                label="Service Tutorial Completed"
                onChange={(e) => {
                  setDriverEvaluationCompleted(Boolean(e.target.value));
                }}
                onFocus={() => {
                  setFormFieldToFocused("driverEvaluationCompleted");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("driverEvaluationCompleted");
                }}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select disabled value={Number(isOnline)} label="Status">
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Offline</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Consented to Criminal Record Check</InputLabel>
              <Select
                value={Number(criminalRecordSubmitted)}
                label="Consented to Criminal Record Check"
                onChange={(e) => {
                  setCriminalRecordSubmitted(Boolean(e.target.value));
                }}
                onFocus={() => {
                  setFormFieldToFocused("criminalRecordSubmitted");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("criminalRecordSubmitted");
                }}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Criminal Record Check Completed</InputLabel>
              <Select
                value={Number(criminalRecordCheckCompleted)}
                label="Criminal Record Check Completed"
                onChange={(e) => {
                  setCriminalRecordCheckCompleted(Boolean(e.target.value));
                }}
                onFocus={() => {
                  setFormFieldToFocused("criminalRecordCheckCompleted");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("criminalRecordCheckCompleted");
                }}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"Service Call Acceptance %"}
              fullWidth
              value={driverAcceptanceRate}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Service Call Cancellation %"}
              fullWidth
              value={driverCancellationRate}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Address"}
              fullWidth
              value={address}
              error={Boolean(isAnyKeyValueTrue(formErrors.address))}
              helperText={isAnyKeyValueTrue(formErrors.address)}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("address");
              }}
              onBlur={() => {
                setFormFieldToBlurred("address");
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"City"}
              fullWidth
              value={city}
              error={Boolean(isAnyKeyValueTrue(formErrors.city))}
              helperText={isAnyKeyValueTrue(formErrors.city)}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("city");
              }}
              onBlur={() => {
                setFormFieldToBlurred("city");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Preferred City</InputLabel>
              <Select
                onChange={(e) => {
                  setPreferredCity(e.target.value);
                }}
                value={preferredCity}
                label="Preferred City"
              >
                {cities.map((city) => (
                  <MenuItem value={city.name}>{city.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Province</InputLabel>
              <Select
                onChange={(e) => {
                  setProvince(e.target.value);
                }}
                value={province}
                label="Province"
              >
                {provinces.map((province) => (
                  <MenuItem value={province.name}>{province.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Postal Code"}
              fullWidth
              value={postalCode}
              error={Boolean(isAnyKeyValueTrue(formErrors.postalCode))}
              helperText={isAnyKeyValueTrue(formErrors.postalCode)}
              onFocus={() => {
                setFormFieldToFocused("postalCode");
              }}
              onBlur={() => {
                setFormFieldToBlurred("postalCode");
              }}
              onChange={(e) => {
                setPostalCode(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled
              label={"Country"}
              fullWidth
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"License Number"}
              fullWidth
              value={drivingLicenseNumber}
              error={Boolean(
                isAnyKeyValueTrue(formErrors.drivingLicenseNumber)
              )}
              helperText={isAnyKeyValueTrue(formErrors.drivingLicenseNumber)}
              onChange={(e) => {
                setDrivingLicenseNumber(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("drivingLicenseNumber");
              }}
              onBlur={() => {
                setFormFieldToBlurred("drivingLicenseNumber");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DriverProfileEditModal;
