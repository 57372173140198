import { Box, CircularProgress, Button as MUIButton } from "@mui/material";

const Button = ({
  children,
  onClick,
  fullWidth,
  loading,
  disabled,
  fontSize = 16,
  icon = null,
}: any) => {
  return (
    <MUIButton
      onClick={onClick}
      disabled={loading || disabled}
      fullWidth={fullWidth}
      variant="contained"
      size="medium"
      sx={{
        fontSize,
        paddingTop: 1.5,
        paddingBottom: 1.5,
        boxShadow: "0px 10px 20px #f4433633",
        textTransform: "none",
        fontWeight: 500,
        background: "#f26822",
        "&:hover": {
          background: "#f26822",
        },
        "&:focus": {
          background: "#f26822",
        },
        alignItems: "center",
      }}
    >
      {loading ? (
        <CircularProgress size={30} sx={{ color: "white", marginRight: 2 }} />
      ) : (
        <>
          {!loading && icon}
          {children}
        </>
      )}
    </MUIButton>
  );
};

export default Button;
