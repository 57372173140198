import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import provinces from "../../../../../utils/provinces.json";
import useFormFieldsBlurState from "../../../../../hooks/useFormFieldsBlurState";
import {
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  validatePostalCode,
  validatePresence,
} from "../../../../../helpers/validationHelpers";
import ErrorHelperText from "../../../../../components/ErrorHelperText/ErrorHelperText";

const AddBillingAddressPage = ({
  street,
  setStreet,
  city,
  setCity,
  province,
  setProvince,
  billingAddressPostalCode,
  setBillingAddressPostalCode,
}: {
  street: any;
  setStreet: any;
  city: any;
  setCity: any;
  province: any;
  setProvince: any;
  billingAddressPostalCode: any;
  setBillingAddressPostalCode: any;
}) => {
  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      street,
      city,
      province,
      billingAddressPostalCode,
    });

  const formErrors = {
    street: {
      empty:
        blurredFormFields.street &&
        !validatePresence(street) &&
        "Please enter a street address",
    },
    city: {
      empty:
        blurredFormFields.city &&
        !validatePresence(city) &&
        "Please enter a city",
    },
    province: {
      empty:
        blurredFormFields.province &&
        !validatePresence(province) &&
        "Please select a province",
    },
    billingAddressPostalCode: {
      empty:
        blurredFormFields.billingAddressPostalCode &&
        !validatePresence(billingAddressPostalCode) &&
        "Please enter a postal code",
      error:
        blurredFormFields.billingAddressPostalCode &&
        validatePresence(billingAddressPostalCode) &&
        !validatePostalCode(billingAddressPostalCode) &&
        "Invalid postal code",
    },
  };

  const streetErrors: any = filterObjectByValues(
    formErrors?.street,
    (key, value) => value
  );
  const cityErrors: any = filterObjectByValues(
    formErrors?.city,
    (key, value) => value
  );

  const provinceErrors: any = filterObjectByValues(
    formErrors?.province,
    (key, value) => value
  );

  const billingAddressPostalCodeErrors: any = filterObjectByValues(
    formErrors?.billingAddressPostalCode,
    (key, value) => value
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          error={false}
          fullWidth
          placeholder="Street"
          label={"Street"}
          value={street}
          onFocus={() => {
            setFormFieldToFocused("street");
          }}
          onChange={(e) => {
            setStreet(e.target.value);
          }}
          onBlur={() => {
            setFormFieldToBlurred("street");
          }}
          variant="outlined"
        />
        {mapObjectValues(streetErrors, (key: any, index: any) => (
          <ErrorHelperText
            key={`billing-address-form-street-errors-${index}`}
            errorText={streetErrors[key]}
          />
        ))}
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          <TextField
            error={false}
            fullWidth
            placeholder="City"
            label={"City"}
            value={city}
            onFocus={() => {
              setFormFieldToFocused("city");
            }}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            onBlur={() => {
              setFormFieldToBlurred("city");
            }}
            variant="outlined"
          />
          {mapObjectValues(cityErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`billing-address-form-city-errors-${index}`}
              errorText={cityErrors[key]}
            />
          ))}
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Province</InputLabel>
            <Select
              variant="outlined"
              onChange={(e) => {
                setProvince(e.target.value);
              }}
              value={province}
              label="Province"
              placeholder="Province"
            >
              {provinces.map((province) => (
                <MenuItem value={province.name}>{province.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {mapObjectValues(provinceErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`billing-address-form-province-errors-${index}`}
              errorText={provinceErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Postal Code"
          label={"Postal Code"}
          value={billingAddressPostalCode}
          onFocus={() => {
            setFormFieldToFocused("billingAddressPostalCode");
          }}
          onChange={(e) => {
            setBillingAddressPostalCode(e.target.value);
          }}
          onBlur={() => {
            setFormFieldToBlurred("billingAddressPostalCode");
          }}
          variant="outlined"
        />
        {mapObjectValues(
          billingAddressPostalCodeErrors,
          (key: any, index: any) => (
            <ErrorHelperText
              key={`billing-address-form-postal-code-${index}`}
              errorText={billingAddressPostalCodeErrors[key]}
            />
          )
        )}
      </Grid>
    </Grid>
  );
};

export default AddBillingAddressPage;
