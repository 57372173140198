import { Grid, Typography } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
const ErrorHelperText = ({ errorText }: { errorText: string }) => {
  return (
    <Grid
      flexWrap={"nowrap"}
      container
      item
      alignItems={"center"}
      alignContent={"center"}
      spacing={1}
    >
      <Grid item>
        <Typography color="#EA4444" fontSize={12} fontWeight={400}>
          {errorText}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorHelperText;
