import { GridSortModel } from "@mui/x-data-grid";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { getCsvFilename } from "../helpers/formatters";
const getDrivers = async (
  accessToken: string,
  pageParam: number,
  search?: string,
  sort?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/drivers?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getDriverDetails = async (accessToken: string, cognitoId?: string) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${cognitoId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getDriverServiceCallHistory = async ({
  accessToken,
  cognitoId,
  page,
  sort,
}: {
  accessToken?: string;
  cognitoId?: string;
  page: number;
  sort?: string;
}) => {
  try {
    console.log("sort in method", sort);
    if (accessToken || cognitoId) {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/driver/${cognitoId}/service-call-history?page=${page}${
          sort ? `&${sort}` : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getDriverEarnings = async ({
  accessToken,
  cognitoId,
}: {
  accessToken?: string;
  cognitoId?: string;
}) => {
  try {
    if (!cognitoId) {
      return [];
    }
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${cognitoId}/earnings`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const updateDriverProfile = async ({
  accessToken,
  cognitoId,
  driverFields,
}: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${cognitoId}/update-profile`,
      driverFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const approveDriverProfileChanges = async ({ accessToken, cognitoId }: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${cognitoId}/approve-driver-profile-changes`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const updateDriverVehicleInformation = async ({
  accessToken,
  cognitoId,
  driverFields,
}: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${cognitoId}/update-vehicle-information`,
      driverFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const toggleDriverApprovalStatus = async ({
  accessToken,
  cognitoId,
  driverEvaluationCompleted,
}: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${cognitoId}/update-driver-approval-status`,
      { driverEvaluationCompleted },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const useDriverEarnings = ({
  accessToken,
  cognitoId,
}: {
  accessToken?: string;
  cognitoId?: string;
}) => {
  return useQuery({
    queryKey: ["driver-earnings", cognitoId],
    queryFn: () => getDriverEarnings({ accessToken, cognitoId }),
  });
};

// const useDriversInfiniteQuery = (accessToken: string) => {
//   return useInfiniteQuery({
//     queryKey: ["drivers"],
//     queryFn: ({ pageParam = 0 }) => getDrivers(accessToken, pageParam),
//     getNextPageParam: (lastPage, allPages) => lastPage?.nextPage,
//   });
// };

const useDrivers = ({
  accessToken,
  page = 0,
  search,
  sort,
}: {
  accessToken: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
}) => {
  return useQuery({
    queryKey: [
      "drivers",
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getDrivers(
        accessToken,
        page,
        search,
        sort && sort?.length > 0 ? qs.stringify({ sortBy: sort[0] }) : undefined
      ),
    keepPreviousData: true,
  });
};

const useDriverServiceCallHistory = ({
  accessToken,
  cognitoId,
  page = 0,
  sort,
}: {
  accessToken?: string;
  cognitoId?: string;
  page: number;
  sort: GridSortModel;
}) => {
  console.log("sort in serviceCallhistory", sort);
  return useQuery({
    queryKey: [
      "driver-service-calls",
      cognitoId,
      page,
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getDriverServiceCallHistory({
        accessToken,
        cognitoId,
        page,
        sort:
          sort && sort?.length > 0
            ? qs.stringify({ sortBy: sort[0] })
            : undefined,
      }),
  });
};

const getDriversCSV = async (accessToken: string) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/drivers-csv`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const filename = getCsvFilename(headers);
    return { data, filename };
  } catch (error) {
    console.log("error", error);
  }
};

const useDriverDetails = (accessToken: string, cognitoId?: string) => {
  return useQuery({
    queryKey: ["driver", cognitoId],
    queryFn: () => getDriverDetails(accessToken, cognitoId),
  });
};

export {
  getDrivers,
  useDrivers,
  getDriverDetails,
  useDriverDetails,
  useDriverServiceCallHistory,
  useDriverEarnings,
  updateDriverProfile,
  updateDriverVehicleInformation,
  toggleDriverApprovalStatus,
  approveDriverProfileChanges,
  getDriversCSV,
};
