import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  isAnyKeyValueTrue,
  validatePresence,
} from "../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../hooks/useFormFieldsBlurState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCustomerProfile } from "../../hooks/useCustomers";
import { createCompany, updateCompanyProfile } from "../../hooks/useCompanies";
import ImageUploadSection from "../../components/ImageUploadSection/ImageUploadSection";
import { uploadAssetToCloudinary } from "../../utils/cloudinary";

const CompanyCreateModal = ({ accessToken, open, setOpen }: any) => {
  const queryClient = useQueryClient();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [addressLineOne, setAddressLineOne] = useState("");
  const [companyLogoUploadLoading, setCompanyLogoUploadLoading] =
    useState(false);
  const [companyLogo, setCompanyLogo] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      name,
      description,
    });

  const formErrors = {
    name: {
      error:
        blurredFormFields.name &&
        !validatePresence(name) &&
        "Please enter a company name",
    },
    description: {
      error:
        blurredFormFields.description &&
        !validatePresence(description) &&
        "Please enter a company description",
      descriptionTooLong:
        blurredFormFields.description &&
        validatePresence(description) &&
        description.length > 250 &&
        "Description must be less than 250 characters long",
    },
  };

  const submitButtonDisabled =
    !validatePresence(name) ||
    !validatePresence(description) ||
    (validatePresence(description) && description.length > 250);

  const resetFormFields = () => {
    setName("");
    setDescription("");
    setAddressLineOne("");
    setAddressLineTwo("");
    setCity("");
    setProvince("");
    setCountry("");
    setCompanyLogo("");
  };

  const {
    mutate: handleCompanyCreate,
    isLoading: isCompanyProfileCreateLoading,
  } = useMutation({
    mutationFn: createCompany,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.invalidateQueries({ queryKey: ["companies"] });
      setShowSuccess(true);
      setOpen(false);
      resetFormFields();
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
      // setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    setShowError(false);
    setErrorMessage("");
    handleCompanyCreate({
      accessToken,
      companyFields: {
        name,
        description,
        addressLineOne,
        companyLogo,
        addressLineTwo,
        city,
        province,
        country,
      },
    });
  };

  const handleCompanyLogoUpload = async (file: any) => {
    setCompanyLogoUploadLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, "company-logos");
      setCompanyLogo(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setCompanyLogoUploadLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      loading={isCompanyProfileCreateLoading}
      onSubmit={handleSubmit}
      onCancel={() => {
        setOpen(false);
      }}
      handleClose={() => {
        if (!isCompanyProfileCreateLoading) {
          setOpen(false);
        }
      }}
      submitButtonLabel="Create"
      title="Company Details"
      submitButtonDisabled={submitButtonDisabled || companyLogoUploadLoading}
    >
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowSuccess(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            New company successfully created
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
            setErrorMessage("");
          }}
        >
          <Alert
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Company Name"}
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("name");
              }}
              onBlur={() => {
                setFormFieldToBlurred("name");
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.name))}
              helperText={isAnyKeyValueTrue(formErrors.name)}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Description"}
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("description");
              }}
              onBlur={() => {
                setFormFieldToBlurred("description");
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.description))}
              helperText={isAnyKeyValueTrue(formErrors.description)}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"Address line #1"}
              fullWidth
              value={addressLineOne}
              onChange={(e) => {
                setAddressLineOne(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Address line #2"}
              fullWidth
              value={addressLineTwo}
              onChange={(e) => {
                setAddressLineTwo(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"City"}
              fullWidth
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Province"}
              fullWidth
              value={province}
              onChange={(e) => {
                setProvince(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Country"}
              fullWidth
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography>Company Logo:</Typography>
          </Grid>
          <ImageUploadSection
            uploadedFile={companyLogo}
            loading={companyLogoUploadLoading}
            label={"Upload company logo"}
            handleUpload={handleCompanyLogoUpload}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CompanyCreateModal;
