import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import MuveIt from "../../assets/images/muveit.png";
import { MailOutlined, LockOutlined, ChevronLeft } from "@mui/icons-material";
import { useState } from "react";
import {
  isAnyKeyValueTrue,
  validateEmail,
  validatePassword,
} from "../../helpers/validationHelpers";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordBlurred, setPasswordBlurred] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const errors = {
    email: {
      empty: !email && "Please enter an email",
      invalidEmail:
        email.length > 0 &&
        !validateEmail(email) &&
        "Please enter a valid email address",
    },
  };

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      item
      xs={12}
      md={5}
      sx={{ background: "#fff" }}
    >
      <Grid item mt={"13vh"} mb={"14vh"}>
        <img src={MuveIt} style={{ maxHeight: "100%", height: "auto" }} />
      </Grid>
      <Grid
        maxWidth={380}
        container
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item mb={4}>
          <Typography variant="h2" fontSize={36} fontWeight={700}>
            Forgot password?
          </Typography>
          <Typography color={"#868686"} fontSize={16} lineHeight={"28px"}>
            Fill the form to reset your password
          </Typography>
        </Grid>
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={3}
        >
          <Grid item width={"100%"}>
            <TextField
              fullWidth
              value={email}
              onFocus={() => {
                setEmailBlurred(false);
              }}
              onBlur={(e) => {
                setEmailBlurred(true);
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
              label="Email Address"
              helperText={emailBlurred && isAnyKeyValueTrue(errors.email)}
              error={emailBlurred && Boolean(isAnyKeyValueTrue(errors.email))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlined />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>
          <Grid item width="100%">
            <Button
              fullWidth
              onClick={() => {
                console.log("forgot password clicked");
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          mt={3}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/login");
          }}
        >
          <ChevronLeft /> <Typography fontSize={14}>Return to Login</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordForm;
