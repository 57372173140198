import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAdminDashboardStats = async (accessToken: string) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/dashboard-stats`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log("error", error);
  }
};

const useAdminDashboardStats = (accessToken: string) => {
  return useQuery({
    queryKey: ["admin-dashboard-stats"],
    queryFn: () => getAdminDashboardStats(accessToken),
  });
};

export { getAdminDashboardStats, useAdminDashboardStats };
