import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { GridSortModel } from "@mui/x-data-grid";
import qs from "qs";
import {
  DriverVehicleInformationVehicleTypes,
  ServiceDriverTypes,
} from "../helpers/validationHelpers";

const getCompanyPickUpRequestVoucherTypes = async (
  accessToken: string,
  companyId: string,
  pageParam: number,
  search?: string,
  sort?: string
) => {
  try {
    if (accessToken) {
      const result = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/company/${companyId}/company-pickup-request-voucher-types?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return result?.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const deleteCompanyPickUpRequestVoucherType = async ({
  accessToken,
  companyId,
  companyPickUpRequestVoucherTypeId,
}: {
  accessToken: string;
  companyId: string;
  companyPickUpRequestVoucherTypeId: string;
}) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/delete-company-pickup-request-voucher-type/${companyPickUpRequestVoucherTypeId}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const addNewCompanyVoucherType = async ({
  accessToken,
  companyId,
  name,
  driverType,
  vehicleType,
  distanceLimitInMeters,
  priceInCents,
  maxHeightInFeet,
  maxLengthInFeet,
  maxWidthInFeet,
  maxWeightInPounds,
}: {
  accessToken: string;
  companyId: string;
  name: string;
  driverType: ServiceDriverTypes;
  vehicleType: DriverVehicleInformationVehicleTypes;
  distanceLimitInMeters: number;
  priceInCents: number;
  maxHeightInFeet: number;
  maxLengthInFeet: number;
  maxWidthInFeet: number;
  maxWeightInPounds: number;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/create-new-company-pickup-request-voucher-type`,
      {
        name,
        driverType,
        vehicleType,
        distanceLimitInMeters,
        priceInCents,
        maxHeightInFeet,
        maxLengthInFeet,
        maxWidthInFeet,
        maxWeightInPounds,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const updateCompanyVoucherType = async ({
  accessToken,
  companyId,
  companyPickUpRequestVoucherTypeId,
  name,
  driverType,
  vehicleType,
  distanceLimitInMeters,
  priceInCents,
  maxHeightInFeet,
  maxLengthInFeet,
  maxWidthInFeet,
  maxWeightInPounds,
}: {
  accessToken: string;
  companyId: string;
  name: string;
  companyPickUpRequestVoucherTypeId: string;
  driverType: ServiceDriverTypes;
  vehicleType: DriverVehicleInformationVehicleTypes;
  distanceLimitInMeters: number;
  priceInCents: number;
  maxHeightInFeet: number;
  maxLengthInFeet: number;
  maxWidthInFeet: number;
  maxWeightInPounds: number;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/update-company-pickup-request-voucher-type/${companyPickUpRequestVoucherTypeId}`,
      {
        name,
        driverType,
        vehicleType,
        distanceLimitInMeters,
        priceInCents,
        maxHeightInFeet,
        maxLengthInFeet,
        maxWidthInFeet,
        maxWeightInPounds,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const useCompanyPickUpRequestVoucherTypes = ({
  accessToken,
  page = 0,
  companyId,
  search,
  sort,
}: {
  accessToken: string;
  companyId: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
}) => {
  return useQuery({
    queryKey: [
      "company-pickup-request-voucher-types",
      companyId,
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getCompanyPickUpRequestVoucherTypes(
        accessToken,
        companyId,
        page,
        search,
        sort && sort?.length > 0 ? qs.stringify({ sortBy: sort[0] }) : undefined
      ),
    keepPreviousData: true,
  });
};

export {
  getCompanyPickUpRequestVoucherTypes,
  useCompanyPickUpRequestVoucherTypes,
  addNewCompanyVoucherType,
  deleteCompanyPickUpRequestVoucherType,
  updateCompanyVoucherType,
};
