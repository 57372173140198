import { GridSortModel } from "@mui/x-data-grid";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { getCsvFilename } from "../helpers/formatters";
const getCustomers = async (
  accessToken: string,
  pageParam: number,
  search?: string,
  sort?: string
) => {
  try {
    if (accessToken) {
      console.log("sort", sort);
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/customers?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getCustomerPayments = async ({
  accessToken,
  cognitoId,
  model,
}: {
  accessToken?: string;
  cognitoId?: string;
  model: any;
}) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/customer/payments/${cognitoId}?page=${model.page}${
          model.startingAfter ? `&startingAfter=${model.startingAfter}` : ""
        }${model.endingBefore ? `&endingBefore=${model.endingBefore}` : ""}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getCustomerDetails = async (accessToken: string, cognitoId?: string) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/customer/${cognitoId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getCustomerServiceCallHistory = async ({
  accessToken,
  cognitoId,
  page,
  sort,
}: {
  accessToken?: string;
  cognitoId?: string;
  page: number;
  sort?: string;
}) => {
  try {
    if (accessToken || cognitoId) {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/customer/${cognitoId}/service-call-history?page=${page}${
          sort ? `&${sort}` : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const updateCustomerProfile = async ({
  accessToken,
  cognitoId,
  customerFields,
}: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/customer/${cognitoId}/update-profile`,
      customerFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const getCustomersCSV = async (accessToken: string) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/customers-csv`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const filename = getCsvFilename(headers);
    return { data, filename };
  } catch (error) {
    console.log("error", error);
  }
};

// const useCustomersInfiniteQuery = (accessToken: string) => {
//   return useInfiniteQuery({
//     queryKey: ["customers"],
//     queryFn: ({ pageParam = 0 }) => getCustomers(accessToken, pageParam),
//     getNextPageParam: (lastPage, allPages) => lastPage?.nextPage,
//   });
// };

const useCustomerPayments = ({
  accessToken,
  cognitoId,
  model,
}: {
  accessToken?: string;
  cognitoId?: string;
  model: any;
}) => {
  return useQuery({
    queryKey: ["customer-payments", cognitoId, model.page],
    queryFn: () => getCustomerPayments({ accessToken, cognitoId, model }),
  });
};

const useCustomers = ({
  accessToken,
  page = 0,
  search,
  sort,
}: {
  accessToken: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
}) => {
  return useQuery({
    queryKey: [
      "customers",
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getCustomers(
        accessToken,
        page,
        search,
        sort && sort?.length > 0 ? qs.stringify({ sortBy: sort[0] }) : undefined
      ),
    keepPreviousData: true,
  });
};

const useCustomerDetails = (accessToken: string, cognitoId?: string) => {
  return useQuery({
    queryKey: ["customer", cognitoId],
    queryFn: () => getCustomerDetails(accessToken, cognitoId),
  });
};

const useCustomerServiceCallHistory = ({
  accessToken,
  cognitoId,
  page = 0,
  sort,
}: {
  accessToken?: string;
  cognitoId?: string;
  page: number;
  sort: GridSortModel;
}) => {
  return useQuery({
    queryKey: [
      "customer-service-calls",
      cognitoId,
      page,
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getCustomerServiceCallHistory({
        accessToken,
        cognitoId,
        page,
        sort:
          sort && sort?.length > 0
            ? qs.stringify({ sortBy: sort[0] })
            : undefined,
      }),
  });
};

export {
  getCustomers,
  useCustomers,
  useCustomerDetails,
  getCustomerDetails,
  useCustomerServiceCallHistory,
  getCustomerServiceCallHistory,
  updateCustomerProfile,
  useCustomerPayments,
  getCustomersCSV,
};
