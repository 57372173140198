import { Grid, Typography } from "@mui/material";

const ProfileListItem = ({ label, value }: { label: string; value: any }) => {
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
      overflowWrap: "break-word",
    },
  };
  return (
    <Grid container item justifyContent={"flex-start"}>
      <Grid item xs={6}>
        <Typography sx={styles.listLabel}>{label}:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.listValue}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

export default ProfileListItem;
