import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import PickUpTruckImage from "../../../assets/images/truck.png";
import CargoVanImage from "../../../assets/images/cargovan.png";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import { EditOutlined } from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";
import DriverVehicleEditModal from "./DriverVehicleEditModal";
import ImageModal from "../../../components/ImageModal/ImageModal";
import { mappedVehicleTypes } from "../../../helpers/validationHelpers";
const VehiclesTab = ({
  driverVehicleInformation,
  accessToken,
  cognitoId,
}: any) => {
  const [open, setOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageToLoad, setImageToLoad] = useState({ label: "", value: "" });

  //   if (!driverVehicleInformation) {
  //     return (
  //       <Grid justifyContent={"center"} container item>
  //         <Grid item>
  //           <CircularProgress />
  //         </Grid>
  //       </Grid>
  //     );
  //   }

  const primaryListSection = [
    {
      label: "Category",
      value: mappedVehicleTypes[driverVehicleInformation?.vehicleType],
    },
    {
      label: "Model/Make",
      value: driverVehicleInformation?.makeOfCar,
    },
    {
      label: "Production Year",
      value: driverVehicleInformation?.carYear,
    },
  ];

  const secondaryListSection = [
    {
      label: "License Plate #",
      value: driverVehicleInformation?.licensePlateNumber,
    },
    {
      label: "Created At",
      value: moment(driverVehicleInformation?.createdAt).format(
        "Do MMMM  YYYY, h:mm a"
      ),
    },
    {
      label: "Color",
      value: driverVehicleInformation?.carColor,
    },
    {
      label: "Insurance Expiry",
      value: moment(driverVehicleInformation?.insuranceExpiryDate).format(
        "YYYY-MM-DD"
      ),
    },
  ];

  const imageListSection = [
    {
      label: "Vehicle Side",
      value: driverVehicleInformation?.vehicleSideUpload,
    },
    {
      label: "Vehicle Cargo Bed",
      value: driverVehicleInformation?.vehicleCargoBedUpload,
    },
    {
      label: "Vehicle Proof of Ownership",
      value: driverVehicleInformation?.vehicleProofOfOwnershipUpload,
    },
    {
      label: "Vehicle Insurance",
      value: driverVehicleInformation?.vehicleInsuranceUpload,
    },
  ].filter((image) => image.value);

  console.log("imageListSection", imageListSection);

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      rowSpacing={2}
      container
      direction={"column"}
      justifyContent={"space-evenly"}
    >
      <IconButton
        sx={{ position: "absolute", top: "15px", right: "15px" }}
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditOutlined sx={{ color: "#28a1fa" }} />
      </IconButton>
      <Grid item>
        <Typography fontWeight={600} fontSize={16}>
          Summary
        </Typography>
      </Grid>

      <DriverVehicleEditModal
        cognitoId={cognitoId}
        accessToken={accessToken}
        open={open}
        setOpen={setOpen}
        driverVehicleType={driverVehicleInformation?.vehicleType}
        driverLicensePlateNumber={driverVehicleInformation?.licensePlateNumber}
        driverMakeOfCar={driverVehicleInformation?.makeOfCar}
        driverCarYear={driverVehicleInformation?.carYear}
        driverCarColor={driverVehicleInformation?.carColor}
        imageListSection={imageListSection}
      />

      <Grid container item direction="row" paddingBottom={2}>
        <Grid item xs={12} sm={2} md={2}>
          {driverVehicleInformation?.vehicleType === "PICKUPTRUCK" ? (
            <img src={PickUpTruckImage} />
          ) : (
            <img src={CargoVanImage} />
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Grid container direction={"column"}>
            {primaryListSection.map(({ label, value }, index) => (
              <ProfileListItem
                key={`vehicle-information-primary-list-section-${index}`}
                label={label}
                value={value}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Grid container direction={"column"}>
            {secondaryListSection.map(({ label, value }, index) => (
              <ProfileListItem
                key={`vehicle-information-secondary-list-section-${index}`}
                label={label}
                value={value}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Divider />
      <Grid container item justifyContent={"flex-start"}>
        <Grid item xs={12}>
          <Typography fontWeight={600} fontSize={16}>
            Vehicle Photos
          </Typography>
        </Grid>
        <ImageModal
          open={imageModalOpen}
          title={imageToLoad.label}
          imageURL={imageToLoad.value}
          handleClose={() => {
            setImageToLoad({ label: "", value: "" });
            setImageModalOpen(false);
          }}
        />
        <Grid item xs={12}>
          <ImageList cols={imageListSection?.length}>
            {imageListSection.map((item, index) => (
              <ImageListItem
                onClick={() => {
                  setImageToLoad(item);
                  setImageModalOpen(true);
                }}
                sx={{ height: 185, cursor: "pointer" }}
                key={`vehicle-information-vehicle-photo-list-section-${index}`}
              >
                <img
                  srcSet={`${item.value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.value}?w=164&h=164&fit=crop&auto=format`}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VehiclesTab;
