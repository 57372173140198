import {
  Alert,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  DriverVehicleInformationVehicleTypes,
  ServiceDriverTypes,
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
} from "../../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../../hooks/useFormFieldsBlurState";
import ErrorHelperText from "../../../../components/ErrorHelperText/ErrorHelperText";
import {
  convertDollarsToCents,
  convertKilometersToMeters,
  convertToKilometers,
  roundToPrecision,
} from "../../../../helpers/formatters";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addNewCompanyVoucherType,
  updateCompanyVoucherType,
} from "../../../../hooks/useCompanyPickUpRequestVoucherTypes";

const CompanyVoucherModal = ({
  open,
  setOpen,
  editMode = false,
  selectedCompanyVoucher = null,
  setSelectedCompanyVoucher,
  accessToken,
  companyId,
}: {
  open: any;
  setOpen: any;
  editMode?: boolean;
  selectedCompanyVoucher?: any;
  setSelectedCompanyVoucher: any;
  companyId: string;
  accessToken: string;
}) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [driverType, setDriverType] = useState(ServiceDriverTypes.none);
  const [vehicleType, setVehicleType] = useState(
    DriverVehicleInformationVehicleTypes.none
  );
  const [distanceLimitInKilometers, setDistanceLimitInKilometers] = useState(0);
  const [maxLengthInFeet, setMaxLengthInFeet] = useState(0);
  const [maxWidthInFeet, setMaxWidthInFeet] = useState(0);
  const [maxHeightInFeet, setMaxHeightInFeet] = useState(0);
  const [maxWeightInPounds, setMaxWeightInPounds] = useState(0);
  const [priceInDollars, setPriceInDollars] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    name,
    driverType,
    vehicleType,
    distanceLimitInKilometers,
    maxLengthInFeet,
    maxWidthInFeet,
    maxHeightInFeet,
    maxWeightInPounds,
    priceInDollars,
  });

  useEffect(() => {
    if (editMode && selectedCompanyVoucher) {
      setName(selectedCompanyVoucher?.name);
      setDriverType(selectedCompanyVoucher?.driverType);
      setVehicleType(selectedCompanyVoucher?.vehicleType);
      setDistanceLimitInKilometers(
        convertToKilometers(selectedCompanyVoucher?.distanceLimitInMeters)
      );
      setMaxLengthInFeet(selectedCompanyVoucher?.maxLengthInFeet);
      setMaxHeightInFeet(selectedCompanyVoucher?.maxHeightInFeet);
      setMaxWidthInFeet(selectedCompanyVoucher?.maxWidthInFeet);
      setMaxWeightInPounds(selectedCompanyVoucher?.maxWeightInPounds);
      setPriceInDollars(
        roundToPrecision(Number(selectedCompanyVoucher?.price) / 100, 2)
      );
    }
  }, [editMode, selectedCompanyVoucher]);

  const formErrors: any = {
    name: {
      empty:
        blurredFormFields?.name &&
        !name &&
        "Please enter a name for this SKU (i.e: MI_120)",
    },
    driverType: {
      empty:
        blurredFormFields?.driverType &&
        !driverType &&
        "Please select a driver type",
    },
    vehicleType: {
      empty:
        blurredFormFields?.vehicleType &&
        !vehicleType &&
        "Please select a vehicle type",
    },
    distanceLimitInKilometers: {
      empty:
        blurredFormFields?.distanceLimitInKilometers &&
        !distanceLimitInKilometers &&
        "Please enter a cargo distance limit",
    },
    maxLengthInFeet: {
      empty:
        blurredFormFields?.maxLengthInFeet &&
        !maxLengthInFeet &&
        "Please enter a cargo length limit",
    },
    maxWidthInFeet: {
      empty:
        blurredFormFields?.maxWidthInFeet &&
        !maxWidthInFeet &&
        "Please enter a cargo width limit",
    },
    maxHeightInFeet: {
      empty:
        blurredFormFields?.maxHeightInFeet &&
        !maxHeightInFeet &&
        "Please enter a cargo height limit",
    },
    maxWeightInPounds: {
      empty:
        blurredFormFields?.maxWeightInPounds &&
        !maxWeightInPounds &&
        "Please enter a cargo weight limit",
    },
    priceInDollars: {
      empty:
        blurredFormFields?.priceInDollars &&
        !priceInDollars &&
        "Please enter a price for this SKU",
      lessThanOneDollar:
        blurredFormFields?.priceInDollars &&
        priceInDollars &&
        priceInDollars < 1 &&
        "Price must be at 1 dollar",
    },
  };

  const nameErrors: any = filterObjectByValues(
    formErrors?.name,
    (key, value) => value
  );
  const distanceLimitInKilometersErrors: any = filterObjectByValues(
    formErrors?.distanceLimitInKilometers,
    (key, value) => value
  );

  const maxHeightInFeetErrors: any = filterObjectByValues(
    formErrors?.maxHeightInFeet,
    (key, value) => value
  );
  const maxLengthInFeetErrors: any = filterObjectByValues(
    formErrors?.maxLengthInFeet,
    (key, value) => value
  );
  const maxWidthInFeetErrors: any = filterObjectByValues(
    formErrors?.maxWidthInFeet,
    (key, value) => value
  );

  const maxWeightInPoundsErrors: any = filterObjectByValues(
    formErrors?.maxWeightInPounds,
    (key, value) => value
  );

  const driverTypeErrors: any = filterObjectByValues(
    formErrors?.driverType,
    (key, value) => value
  );

  const vehicleTypeErrors: any = filterObjectByValues(
    formErrors?.vehicleType,
    (key, value) => value
  );

  const priceInDollarsErrors: any = filterObjectByValues(
    formErrors?.priceInDollars,
    (key, value) => value
  );

  const submitButtonDisabled =
    !name ||
    !driverType ||
    !vehicleType ||
    !distanceLimitInKilometers ||
    !maxHeightInFeet ||
    !maxLengthInFeet ||
    !maxWidthInFeet ||
    !maxWeightInPounds ||
    !priceInDollars ||
    Boolean(priceInDollars && priceInDollars < 1);

  const handleNumberInputChange = (e: any, setValue: any) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setValue(e.target.value);
    }
  };

  const resetFormFields = () => {
    setName("");
    setDriverType(ServiceDriverTypes.none);
    setVehicleType(DriverVehicleInformationVehicleTypes.none);
    setDistanceLimitInKilometers(0);
    setMaxHeightInFeet(0);
    setMaxLengthInFeet(0);
    setMaxWidthInFeet(0);
    setMaxWeightInPounds(0);
    setPriceInDollars(0);
    setSelectedCompanyVoucher(null);
    resetBlurState();
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
  };

  const {
    mutate: handleAddNewCompanyVoucherType,
    isLoading: isAddNewCompanyVoucherLoading,
  } = useMutation({
    mutationFn: addNewCompanyVoucherType,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });

      await queryClient.cancelQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });
      setOpen(false);
      resetFormFields();

      setSuccessMessage("New SKU type successfully created");
    },
    onError: async (error: any) => {
      console.log("error adding new payment method", error);
      setSubmitError(error);
    },
  });

  const {
    mutate: handleUpdateCompanyVoucherType,
    isLoading: isUpdateCompanyVoucherLoading,
  } = useMutation({
    mutationFn: updateCompanyVoucherType,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });

      await queryClient.cancelQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });
      setOpen(false);
      resetFormFields();

      setSuccessMessage("SKU type successfully updated");
    },
    onError: async (error: any) => {
      console.log("error adding new payment method", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    console.log("editMode", editMode);
    console.log("selectedCompanyVoucher", selectedCompanyVoucher);
    if (editMode && selectedCompanyVoucher?.id) {
      // editMode logic
      handleUpdateCompanyVoucherType({
        accessToken,
        companyId,
        name,
        companyPickUpRequestVoucherTypeId: selectedCompanyVoucher?.id,
        driverType,
        vehicleType,
        distanceLimitInMeters: convertKilometersToMeters(
          distanceLimitInKilometers
        ),
        maxWeightInPounds,
        maxHeightInFeet,
        maxLengthInFeet,
        maxWidthInFeet,
        priceInCents: convertDollarsToCents(priceInDollars),
      });
    } else {
      handleAddNewCompanyVoucherType({
        accessToken,
        companyId,
        name,
        driverType,
        vehicleType,
        distanceLimitInMeters: convertKilometersToMeters(
          distanceLimitInKilometers
        ),
        maxWeightInPounds,
        maxHeightInFeet,
        maxLengthInFeet,
        maxWidthInFeet,
        priceInCents: convertDollarsToCents(priceInDollars),
      });
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={isAddNewCompanyVoucherLoading}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        submitButtonLabel={editMode ? "Update" : "Create"}
        title="Update SKU"
        submitButtonDisabled={submitButtonDisabled}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(nameErrors)}
                fullWidth
                placeholder="SKU Name"
                label="SKU Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("name");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("name");
                }}
                variant={"outlined"}
              />
              {mapObjectValues(nameErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`sku-modal-name-errors-${index}`}
                  errorText={nameErrors[key]}
                />
              ))}
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(distanceLimitInKilometersErrors)}
                fullWidth
                placeholder="Distance Limit (In KM)"
                label="Distance Limit (In KM)"
                value={distanceLimitInKilometers}
                onFocus={() => {
                  setFormFieldToFocused("distanceLimitInKilometers");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setDistanceLimitInKilometers);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("distanceLimitInKilometers");
                  setDistanceLimitInKilometers((prev: any) => Number(prev));
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">KM</InputAdornment>
                  ),
                }}
              />
              {mapObjectValues(
                distanceLimitInKilometersErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`sku-modal-distance-limit-in-kilometers-errors-${index}`}
                    errorText={distanceLimitInKilometersErrors[key]}
                  />
                )
              )}
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(maxHeightInFeetErrors)}
                fullWidth
                placeholder="Max Cargo Height (In Feet)"
                label="Max Cargo Height (In Feet)"
                value={maxHeightInFeet}
                onFocus={() => {
                  setFormFieldToFocused("maxHeightInFeet");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setMaxHeightInFeet);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("maxHeightInFeet");
                  setMaxHeightInFeet((prev: any) => Number(prev));
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ft</InputAdornment>
                  ),
                }}
              />
              {mapObjectValues(
                maxHeightInFeetErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`sku-modal-cargo-height-errors-${index}`}
                    errorText={maxHeightInFeetErrors[key]}
                  />
                )
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(maxWidthInFeetErrors)}
                fullWidth
                placeholder="Max Cargo Width (In Feet)"
                label="Max Cargo Width (In Feet)"
                value={maxWidthInFeet}
                onFocus={() => {
                  setFormFieldToFocused("maxWidthInFeet");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setMaxWidthInFeet);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("maxWidthInFeet");
                  setMaxWidthInFeet((prev: any) => Number(prev));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ft</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(maxWidthInFeetErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`sku-modal-cargo-width-errors-${index}`}
                  errorText={maxWidthInFeetErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(maxLengthInFeetErrors)}
                fullWidth
                placeholder="Max Cargo Length (In Feet)"
                label="Max Cargo Length (In Feet)"
                value={maxLengthInFeet}
                onFocus={() => {
                  setFormFieldToFocused("maxLengthInFeet");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setMaxLengthInFeet);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("maxLengthInFeet");
                  setMaxLengthInFeet((prev: any) => Number(prev));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ft</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(
                maxLengthInFeetErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`sku-modal-cargo-length-errors-${index}`}
                    errorText={maxLengthInFeetErrors[key]}
                  />
                )
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(maxWeightInPoundsErrors)}
                fullWidth
                placeholder="Max Cargo Weight (In lbs)"
                label="Max Cargo Weight (In lbs)"
                value={maxWeightInPounds}
                onFocus={() => {
                  setFormFieldToFocused("maxWeightInPounds");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setMaxWeightInPounds);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("maxWeightInPounds");
                  setMaxWeightInPounds((prev: any) => Number(prev));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">lbs</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(
                maxWeightInPoundsErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`sku-modal-cargo-weight-errors-${index}`}
                    errorText={maxWeightInPoundsErrors[key]}
                  />
                )
              )}
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography fontSize={11}>Driver Type</Typography>
                <Select
                  error={objectHasKeys(driverTypeErrors)}
                  value={driverType}
                  label="Driver Type"
                  sx={{
                    "&.Mui-disabled": {
                      "&:before": {
                        borderBottomStyle: "solid",
                      },
                    },
                  }}
                  onChange={(e: any) => {
                    setDriverType(e.target.value);
                  }}
                  onFocus={() => {
                    setFormFieldToFocused("driverType");
                  }}
                  onBlur={() => {
                    setFormFieldToBlurred("driverType");
                  }}
                  variant="outlined"
                >
                  <MenuItem value={ServiceDriverTypes.individual}>
                    Individual
                  </MenuItem>
                  <MenuItem value={ServiceDriverTypes.team}>Team</MenuItem>
                </Select>
              </FormControl>
              {mapObjectValues(driverTypeErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`sku-modal-driver-type-errors-${index}`}
                  errorText={driverTypeErrors[key]}
                />
              ))}
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography fontSize={11}>Vehicle Type</Typography>
                <Select
                  error={objectHasKeys(vehicleTypeErrors)}
                  sx={{
                    "&.Mui-disabled": {
                      "&:before": {
                        borderBottomStyle: "solid",
                      },
                    },
                  }}
                  value={vehicleType}
                  label="Vehicle Type"
                  onChange={(e: any) => {
                    setVehicleType(e.target.value);
                  }}
                  onFocus={() => {
                    setFormFieldToFocused("vehicleType");
                  }}
                  onBlur={() => {
                    setFormFieldToBlurred("vehicleType");
                  }}
                  variant="outlined"
                >
                  <MenuItem
                    value={DriverVehicleInformationVehicleTypes.pickUpTruck}
                  >
                    Pick Up Truck
                  </MenuItem>
                  <MenuItem
                    value={DriverVehicleInformationVehicleTypes.cargoVan}
                  >
                    Cargo Van
                  </MenuItem>
                  <MenuItem
                    value={DriverVehicleInformationVehicleTypes.minivan}
                  >
                    Minivan
                  </MenuItem>
                  <MenuItem value={DriverVehicleInformationVehicleTypes.suv}>
                    SUV
                  </MenuItem>
                  <MenuItem value={DriverVehicleInformationVehicleTypes.sedan}>
                    Sedan
                  </MenuItem>
                </Select>
              </FormControl>
              {mapObjectValues(vehicleTypeErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`sku-modal-vehicle-type-errors-${index}`}
                  errorText={vehicleTypeErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={objectHasKeys(priceInDollarsErrors)}
                fullWidth
                placeholder="Price (In Dollars)"
                label="Price (In Dollars)"
                value={priceInDollars}
                onFocus={() => {
                  setFormFieldToFocused("priceInDollars");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setPriceInDollars);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("priceInDollars");
                  setPriceInDollars((prev: any) => Number(prev));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(priceInDollarsErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`sku-modal-price-in-dollars-errors-${index}`}
                  errorText={priceInDollarsErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyVoucherModal;
