import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPagination,
  GridPaginationModel,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  GridToolbarQuickFilter,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { darken, lighten, styled } from "@mui/material/styles";
import { Box, Button, CircularProgress } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useEffect, useRef, useState } from "react";
import useCSVDownloadState from "../../hooks/useCSVDownloadState";
const PaginationTable = ({
  columns,
  loading,
  rows,
  paginationModel,
  onPaginationModelChange,
  onFilterModelChange,
  onSortModelChange,
  withSearchBar = false,
  filterModel,
  filterMode = "client",
  sortModel,
  sortingMode = "client",
  totalCount,
  pageCount,
  withExport = true,
  onExportButtonClick,
  exportButtonLoading,
}: any) => {
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .Mui-odd": {
      backgroundColor: "#EFF3F5",
      // "&:hover": {
      //   backgroundColor: "#EFF3F5",
      // },
      // "&.Mui-selected": {
      //   backgroundColor: "#EFF3F5",
      //   "&:hover": {
      //     backgroundColor: "#EFF3F5",
      //   },
      // },
    },
  }));

  console.log("isLoading", loading);

  return (
    <StyledDataGrid
      sx={{ backgroundColor: "white", mt: 2 }}
      columns={columns}
      loading={loading}
      rows={rows}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
      }
      // @ts-ignore
      pageSizeOptions={[10]}
      slots={{
        ...(withSearchBar && {
          toolbar: () => (
            <Box
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 2,
                pb: 0,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <GridToolbarQuickFilter size={"medium"} debounceMs={1000} />
              {withExport && (
                <>
                  <Button
                    disabled={exportButtonLoading}
                    onClick={onExportButtonClick}
                    size="small"
                    style={{ color: "rgb(242, 104, 34)" }}
                    startIcon={
                      exportButtonLoading ? (
                        <CircularProgress
                          size={15}
                          sx={{ color: "rgb(242, 104, 34)" }}
                        />
                      ) : (
                        <SaveAltIcon />
                      )
                    }
                  >
                    Export
                  </Button>
                </>
              )}
            </Box>
          ),
        }),
        pagination: () => (
          <GridPagination
            // number of rows (i.e number of records returned from API)
            count={totalCount}
            // The "page" prop 2 lines down from here is used to keep track of the current page.
            // GridPagination starts counting from 0, but...
            page={paginationModel.page}
            ActionsComponent={({ className }) => (
              <Pagination
                disabled={loading}
                color="primary"
                // number of pages (i.e number of pages returned from API)
                count={pageCount}
                // The "page" prop 2 lines down from here is used to keep track of the current page.
                // GridPagination starts counting from 0
                // but """Pagination""" needs to start from 1 or it doesn't know how to cycle between pages properly
                page={paginationModel.page + 1}
                onChange={(event, newPage) => {
                  onPaginationModelChange({
                    ...paginationModel,
                    page: newPage - 1,
                  });
                }}
                className={className}
              />
            )}
          />
        ),
      }}
      filterMode={filterMode}
      filterModel={filterMode === "server" ? filterModel : undefined}
      paginationModel={paginationModel}
      onPaginationModelChange={(model) => {
        onPaginationModelChange(model);
      }}
      onFilterModelChange={(model) => {
        if (filterMode === "server") {
          onFilterModelChange(model);
        }
      }}
      sortingMode={sortingMode}
      sortModel={sortingMode === "server" ? sortModel : undefined}
      onSortModelChange={(model) => {
        if (sortingMode === "server") {
          onSortModelChange(model);
        }
      }}
      paginationMode="server"
      rowCount={totalCount}
    />
  );
};

export default PaginationTable;
