import { useEffect, useState } from "react";
import PaginationTable from "../../../../components/PaginationTable/PaginationTable";
import { useCompanyPickUpRequestVoucherTypes } from "../../../../hooks/useCompanyPickUpRequestVoucherTypes";
import {
  GridSortModel,
  GridPaginationModel,
  GridColDef,
  GridLogicOperator,
  GridFilterModel,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import {
  mappedVehicleTypes,
  toTitleCase,
} from "../../../../helpers/validationHelpers";
import {
  convertToKilometers,
  roundToPrecision,
} from "../../../../helpers/formatters";
import { Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../../../components/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import CompanyVoucherModal from "./CompanyVoucherModal";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyVoucherDeleteModal from "./CompanyVoucherDeleteModal";
const CompanyVoucherTypesTab = ({
  accessToken,
  companyId,
}: {
  accessToken: any;
  companyId: any;
}) => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [selectedCompanyVoucher, setSelectedCompanyVoucher] =
    useState<any>(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    data: companyPickUpRequestVoucherTypesData,
    isLoading: isCompanyPickUpRequestVoucherTypesLoading,
    isFetching,
    refetch,
  } = useCompanyPickUpRequestVoucherTypes({
    accessToken,
    companyId,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "SKU",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 225,
      valueGetter: (params) =>
        `${mappedVehicleTypes[params.row.vehicleType]}, ${toTitleCase(
          params.row.driverType
        )} Service`,
    },
    {
      field: "distanceLimitInMeters",
      headerName: "Distance",
      width: 100,
      valueGetter: (params) =>
        `Up to ${convertToKilometers(params.row.distanceLimitInMeters)}km`,
    },
    {
      field: "maxWeightInPounds",
      headerName: "Max Weight",
      // type: "number",
      width: 100,
      valueGetter: (params) => `${params.row.maxWeightInPounds}lbs`,
    },
    {
      field: "maxDimensions",
      headerName: "Max Dimensions",
      // type: "number",
      width: 150,
      valueGetter: (params) =>
        `${params?.row?.maxLengthInFeet}x${params?.row?.maxWidthInFeet}x${params?.row?.maxHeightInFeet}`,
    },
    {
      field: "price",
      headerName: "Price",
      valueGetter: (params) =>
        `$${roundToPrecision(Number(params?.row?.price) / 100, 2)}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            setSelectedCompanyVoucher(params?.row);
            setOpen(true);
          }}
          label="Edit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            setSelectedCompanyVoucher(params?.row);
            setShowDeleteModal(true);
          }}
          label="Delete"
        />,
      ],
    },
  ];

  useEffect(() => {
    const handleDataChange = () => {
      const isVoucherPresentInData =
        companyPickUpRequestVoucherTypesData?.data.some(
          (voucherType: any) => voucherType.id === selectedCompanyVoucher?.id
        );

      if (!isVoucherPresentInData) {
        setOpen(false);
        setShowDeleteModal(false);
        setSelectedCompanyVoucher(null);
      } else {
        const voucherInData = companyPickUpRequestVoucherTypesData?.data?.find(
          (voucherType: any) => voucherType.id === selectedCompanyVoucher?.id
        );
        setSelectedCompanyVoucher(voucherInData);
      }
    };

    
    if (
      companyPickUpRequestVoucherTypesData?.data &&
      companyPickUpRequestVoucherTypesData?.data.length &&
      selectedCompanyVoucher
    ) {
      handleDataChange();
    } else if (
      !companyPickUpRequestVoucherTypesData?.data ||
      companyPickUpRequestVoucherTypesData?.data.length
    ) {
      setOpen(false);
      setShowDeleteModal(false);
      setSelectedCompanyVoucher(null);
    }
  }, [companyPickUpRequestVoucherTypesData, selectedCompanyVoucher]);

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      container
      direction={"column"}
    >
      <CompanyVoucherDeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        accessToken={accessToken}
        companyId={companyId}
        selectedCompanyVoucher={selectedCompanyVoucher}
        setSelectedCompanyVoucher={setSelectedCompanyVoucher}
      />
      <CompanyVoucherModal
        open={open}
        setOpen={setOpen}
        editMode={Boolean(selectedCompanyVoucher)}
        selectedCompanyVoucher={selectedCompanyVoucher}
        setSelectedCompanyVoucher={setSelectedCompanyVoucher}
        accessToken={accessToken}
        companyId={companyId}
      />
      <Grid
        container
        item
        justifyContent={"space-between"}
        alignItems={"center"}
        alignContent={"center"}
        pb={2}
      >
        <Grid item>
          <Typography fontWeight={600} fontSize={16}>
            SKU Information
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            icon={<AddIcon sx={{ marginRight: 2 }} />}
          >
            Create new SKU
          </Button>
        </Grid>
      </Grid>
      <Grid container item>
        <PaginationTable
          withSearchBar
          withExport={false}
          loading={isCompanyPickUpRequestVoucherTypesLoading}
          columns={columns}
          paginationModel={paginationModel}
          rows={companyPickUpRequestVoucherTypesData?.data ?? []}
          paginationMode="server"
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={companyPickUpRequestVoucherTypesData?.totalCount}
          pageCount={companyPickUpRequestVoucherTypesData?.totalPages}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyVoucherTypesTab;
