import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, Divider, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({
  open,
  loading = false,
  handleClose,
  title,
  children,
  onCancel,
  onSubmit,
  submitButtonLabel = "Submit",
  cancelButtonLabel = "Cancel",
  submitButtonDisabled = false,
  withCancelButton = true,
  cancelButtonIcon = null,
}: {
  open: boolean;
  loading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  handleClose: () => void;
  title: string;
  children: React.ReactElement;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  submitButtonDisabled?: boolean;
  withCancelButton?: boolean;
  cancelButtonIcon?: JSX.Element | null;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle component={"div"} sx={{ py: 1 }}>
        <Grid
          container
          justifyContent={"space-between"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Grid item>{title}</Grid>
          <Grid item>
            <IconButton disabled={loading} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        {withCancelButton && (
          <Button
            sx={{
              background: "#ffffff",
              boxShadow: "0px 3px 6px #00000029",
              border: "1px solid #464646",
              color: "#464646",
              fontSize: "14px",
              borderRadius: 0,
              padding: "8px 20px",
              textTransform: "capitalize",
            }}
            onClick={onCancel}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                size={30}
                sx={{ color: "white", marginRight: 2 }}
              />
            ) : (
              <>{cancelButtonLabel}</>
            )}
          </Button>
        )}
        <Button
          sx={{
            background: "#28a1fa",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #28a1fa",
            color: "#fff",
            fontSize: "14px",
            borderRadius: 0,
            padding: "8px 20px",
            textTransform: "capitalize",
            "&:hover": {
              color: "#28a1fa",
            },
          }}
          disabled={loading || submitButtonDisabled}
          onClick={onSubmit}
        >
          {loading ? (
            <CircularProgress
              size={30}
              sx={{ color: "white", marginRight: 2 }}
            />
          ) : (
            <>{submitButtonLabel}</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
