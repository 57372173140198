import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAdminUserDetails = async (accessToken: string) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/current-admin`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log("error", JSON.stringify(error));
  }
};

const useAdminDetails = (accessToken: string) => {
  return useQuery({
    queryKey: ["current-admin"],
    queryFn: () => getAdminUserDetails(accessToken),
  });
};

export { getAdminUserDetails, useAdminDetails };
