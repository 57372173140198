import { Box, Grid, Hidden, Typography } from "@mui/material";
import LeftLoginSection from "./LeftLoginSection/LeftLoginSection";
import LoginForm from "./LoginForm/LoginForm";
import { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
const Login = () => {
  const { authStatus } = useAuthenticator((context) => [
    context.route,
    context.authStatus,
  ]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/dashboard" || "/";
  useEffect(() => {
    if (authStatus === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [authStatus, navigate, from]);

  return (
    <Grid container height="100vh">
      <LeftLoginSection />
      <LoginForm />
    </Grid>
  );
};

export default Login;
