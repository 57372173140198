import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getBillingSettings = async (accessToken: string) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/billing-settings`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log("error", error);
  }
};

const useBillingSettings = (accessToken: string) => {
  return useQuery({
    queryKey: ["billing-settings"],
    queryFn: () => getBillingSettings(accessToken),
  });
};

export { useBillingSettings, getBillingSettings };
