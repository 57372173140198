import {
  Alert,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import useFormFieldsBlurState from "../../../../hooks/useFormFieldsBlurState";
import {
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  removeWhitespaces,
  validateEmail,
  validatePhoneNumber,
  validatePresence,
} from "../../../../helpers/validationHelpers";
import ErrorHelperText from "../../../../components/ErrorHelperText/ErrorHelperText";
import PhoneNumberTextField from "../../../../components/PhoneNumberTextField/PhoneNumberTextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addNewCompanyStaff,
  modifyCompanyUserType,
} from "../../../../hooks/useCompanyUsers";

const CompanyAccountChangeUserTypeModal = ({
  open,
  setOpen,
  accessToken,
  companyId,
  selectedUser,
  setSelectedUser,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  accessToken: string;
  companyId: string;
  selectedUser: any;
  setSelectedUser: any;
}) => {
  const queryClient = useQueryClient();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("MEMBER");
  const [submitError, setSubmitError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    role,
  });

  const formErrors = {
    role: {
      empty:
        blurredFormFields?.role &&
        !["MEMBER", "ADMIN"].includes(role) &&
        "Please select a user role",
    },
  };

  const roleErrors: any = filterObjectByValues(
    formErrors?.role,
    (key, value) => value
  );

  const submitDisabled = !["MEMBER", "ADMIN"].includes(role);

  const resetFormFields = () => {
    resetBlurState();
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setRole("MEMBER");
    setSubmitError("");
    setSelectedUser(null);
  };

  useEffect(() => {
    if (selectedUser) {
      setFirstName(selectedUser?.firstName);
      setLastName(selectedUser?.lastName);
      setEmail(selectedUser?.email);
      setPhoneNumber(selectedUser?.phoneNumber);
      setRole(selectedUser?.companyAccount?.accountType);
    }
  }, [selectedUser]);

  const { mutate: changeUserType, isLoading: isUserTypeChangeLoading } =
    useMutation({
      mutationFn: modifyCompanyUserType,
      onSuccess: async (data: any) => {
        console.log("data after submit", data);
        await queryClient.cancelQueries({
          queryKey: ["company-users", companyId],
        });

        await queryClient.invalidateQueries({
          queryKey: ["company-users", companyId],
        });
        setOpen(false);
        resetFormFields();

        setSuccessMessage("User updated");
      },
      onError: async (error: any) => {
        console.log("error", error);
        // setShowError(true);
        // setErrorMessage(error);
        setSubmitError("Failed to update user");
      },
    });
  const handleSubmit = () => {
    setSubmitError("");
    changeUserType({
      accessToken,
      companyId,
      cognitoId: selectedUser?.cognitoId,
      accountType: role,
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Modal
        loading={isUserTypeChangeLoading}
        open={open}
        onSubmit={handleSubmit}
        handleClose={handleClose}
        onCancel={handleClose}
        cancelButtonLabel="Cancel"
        submitButtonLabel="Confirm"
        title="Edit User"
        submitButtonDisabled={submitDisabled}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              value={firstName}
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              value={lastName}
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              value={phoneNumber}
              label="Phone Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              value={email}
              label="Email"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontSize={11}>Role</Typography>
              <Select
                error={objectHasKeys(roleErrors)}
                sx={{
                  "&.Mui-disabled": {
                    "&:before": {
                      borderBottomStyle: "solid",
                    },
                  },
                }}
                value={role}
                label="Role"
                onChange={(e: any) => {
                  setRole(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("role");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("role");
                }}
                variant="outlined"
              >
                <MenuItem value={"MEMBER"}>Member</MenuItem>
                <MenuItem value={"ADMIN"}>Admin</MenuItem>
              </Select>
            </FormControl>
            {mapObjectValues(roleErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`account-details-role-errors-${index}`}
                errorText={roleErrors[key]}
              />
            ))}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyAccountChangeUserTypeModal;
