import { useState } from "react";

const useFormFieldsBlurState = (formFields: object) => {
  // copy object and set all values to false
  const initialState: any = {
    ...Object.keys(formFields).reduce(
      (reduced, key) => ({ ...reduced, [key]: false }),
      {}
    ),
  };
  const [blurredFormFields, setBlurredFormFields] = useState(initialState);

  const setFormFieldToBlurred = (keyName: string) => {
    setBlurredFormFields((prev: any) => ({ ...prev, [keyName]: true }));
  };

  const setFormFieldToFocused = (keyName: string) => {
    setBlurredFormFields((prev: any) => ({ ...prev, [keyName]: false }));
  };

  const resetBlurState = () => {
    setBlurredFormFields(initialState);
  };

  return {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  };
};

export default useFormFieldsBlurState;
