import { ButtonBase, Grid, Typography } from "@mui/material";
import PaginationTable from "../../../../components/PaginationTable/PaginationTable";
import { useCompanyUsers } from "../../../../hooks/useCompanyUsers";
import { useEffect, useState } from "react";
import {
  GridSortModel,
  GridPaginationModel,
  GridColDef,
  GridLogicOperator,
  GridFilterModel,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { toTitleCase } from "../../../../helpers/validationHelpers";
import CompanyAccountUserInviteModal from "./CompanyAccountUserInviteModal";
import Button from "../../../../components/Button/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CompanyAccountChangeUserTypeModal from "./CompanyAccountChangeUserTypeModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CompanyAccountUserDeleteModal from "./CompanyUserRemoveModal";
const CompanyAccountsTab = ({
  accessToken,
  companyId,
}: {
  accessToken: any;
  companyId: any;
}) => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const [showCompanyUserInviteModal, setShowCompanyUserInviteModal] =
    useState(false);

  const [showChangeUserTypeModal, setShowChangeUserTypeModal] = useState(false);

  const [showUserRemoveModal, setShowUserRemoveModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>(null);

  const {
    data: companyUsersData,
    isLoading: isCompanyUsersDataLoading,
    isFetching,
  } = useCompanyUsers({
    accessToken,
    companyId,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const columns: GridColDef[] = [
    {
      field: "cognitoId",
      headerName: "ID",
      width: 150,
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 125,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 125,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      // type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      // type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "accountType",
      headerName: "Role",
      valueGetter: (params) =>
        `${toTitleCase(params?.row?.companyAccount?.accountType)}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            setSelectedUser(params?.row);
            setShowChangeUserTypeModal(true);
          }}
          label="Edit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            setSelectedUser(params?.row);
            setShowUserRemoveModal(true);
          }}
          label="Delete"
        />,
      ],
    },
  ];

  useEffect(() => {
    const handleDataChange = () => {
      const isUserPresentInData = companyUsersData?.data.some(
        (voucherType: any) => voucherType.id === selectedUser?.id
      );
      if (!isUserPresentInData) {
        setShowChangeUserTypeModal(false);
        setShowUserRemoveModal(false);
        setSelectedUser(null);
      } else {
        const userInData = companyUsersData?.data?.find(
          (user: any) => user?.id === selectedUser?.id
        );
        setSelectedUser(userInData);
      }
    };

    if (companyUsersData?.data && selectedUser) {
      handleDataChange();
    } else if (!companyUsersData?.data || !companyUsersData?.length) {
      setShowUserRemoveModal(false);
      setShowChangeUserTypeModal(false);
      setSelectedUser(null);
    }
  }, [companyUsersData, selectedUser]);

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      container
      direction={"column"}
    >
      <CompanyAccountUserInviteModal
        accessToken={accessToken}
        companyId={companyId}
        open={showCompanyUserInviteModal}
        setOpen={setShowCompanyUserInviteModal}
      />
      <CompanyAccountChangeUserTypeModal
        accessToken={accessToken}
        companyId={companyId}
        open={showChangeUserTypeModal}
        setOpen={setShowChangeUserTypeModal}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <CompanyAccountUserDeleteModal
        accessToken={accessToken}
        companyId={companyId}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        open={showUserRemoveModal}
        setOpen={setShowUserRemoveModal}
      />
      <Grid
        container
        item
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        pb={2}
      >
        <Grid item>
          <Typography fontWeight={600} fontSize={16}>
            Company Accounts
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              setShowCompanyUserInviteModal(true);
            }}
            icon={<PersonAddIcon sx={{ marginRight: 2 }} />}
          >
            Add new user
          </Button>
        </Grid>
      </Grid>
      <Grid container item>
        <PaginationTable
          withSearchBar
          withExport={false}
          loading={isCompanyUsersDataLoading}
          columns={columns}
          paginationModel={paginationModel}
          rows={companyUsersData?.data ?? []}
          paginationMode="server"
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={companyUsersData?.totalCount}
          pageCount={companyUsersData?.totalPages}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyAccountsTab;
