import { GridSortModel } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { getCsvFilename } from "../helpers/formatters";
const getServiceCalls = async (
  accessToken: string,
  pageParam: number,
  search?: string,
  sort?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/service-calls?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const useServiceCalls = ({
  accessToken,
  page = 0,
  search,
  sort,
}: {
  accessToken: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
}) => {
  return useQuery({
    queryKey: [
      "service-calls",
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getServiceCalls(
        accessToken,
        page,
        search,
        sort && sort?.length > 0 ? qs.stringify({ sortBy: sort[0] }) : undefined
      ),
    keepPreviousData: true,
  });
};

const getServiceCallDetails = async (
  accessToken: string,
  serviceCallId?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/service-call/${serviceCallId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getServiceCallsCSV = async (accessToken: string) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/service-calls-csv`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const filename = getCsvFilename(headers);
    return { data, filename };
  } catch (error) {
    console.log("error", error);
  }
};

const useServiceCallDetails = (accessToken: string, serviceCallId?: string) => {
  return useQuery({
    queryKey: ["service-call", serviceCallId],
    queryFn: () => getServiceCallDetails(accessToken, serviceCallId),
  });
};

export {
  getServiceCalls,
  useServiceCalls,
  useServiceCallDetails,
  getServiceCallsCSV,
};
