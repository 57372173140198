import {
  Alert,
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import { ReactElement, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
const CompanyProfilePanel = ({
  companyLogo,
  companyId,
  name,
  children,
  withEdit = true,
  onEditClick,
}: {
  companyLogo: string;
  companyId: string;
  name: string;
  withEdit?: boolean;
  onEditClick: () => void;
  children: ReactElement;
}) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const showPendingChangesModal = () => {

  // }

  return (
    <Grid item xs={12} lg={4}>
      <Paper sx={{ padding: 2, position: "relative" }}>
        {withEdit && (
          <IconButton
            sx={{ position: "absolute", top: "15px", right: "15px" }}
            color="primary"
            onClick={onEditClick}
          >
            <EditOutlinedIcon sx={{ color: "#28a1fa" }} />
          </IconButton>
        )}
        <Grid container spacing={2}>
          {/* company logo, company name and ID */}
          <Grid item>
            <Avatar sx={{ width: 80, height: 80 }} src={companyLogo} />
          </Grid>
          <Grid container item xs={9} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={700} fontSize={22}>
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: "#610095",
                  padding: "4px 8px",
                  borderRadius: "3px",
                }}
              >
                <Typography sx={{ color: "white" }} fontSize={14}>
                  {companyId}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        {children}
      </Paper>
    </Grid>
  );
};

export default CompanyProfilePanel;
