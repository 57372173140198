import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  isAnyKeyValueTrue,
  validatePresence,
} from "../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCustomerProfile } from "../../../hooks/useCustomers";

const CustomerProfileEditModal = ({
  cognitoId,
  accessToken,
  open,
  setOpen,
  customerFirstName,
  customerLastName,
  customerPhoneNumber,
  customerEmail,
}: any) => {
  const queryClient = useQueryClient();

  const [firstName, setFirstName] = useState(customerFirstName || "");
  const [lastName, setLastName] = useState(customerLastName || "");
  const [phoneNumber, setPhoneNumber] = useState(customerPhoneNumber || "");
  const [email, setEmail] = useState(customerEmail || "");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (open) {
      setFirstName(customerFirstName);
      setLastName(customerLastName);
      setPhoneNumber(customerPhoneNumber);
      setEmail(customerEmail);
    }
  }, [open]);

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      firstName,
      lastName,
    });

  const formErrors = {
    firstName: {
      error:
        blurredFormFields.firstName &&
        !validatePresence(firstName) &&
        "Please enter a first name",
    },
    lastName: {
      error:
        blurredFormFields.lastName &&
        !validatePresence(lastName) &&
        "Please enter a last name",
    },
    // phoneNumber: {
    //   error:
    //     blurredFormFields?.phoneNumber &&
    //     phoneNumber.length > 0 &&
    //     !validatePhoneNumber(phoneNumber),
    //   message: "Phone number is invalid!",
    // },
    // email: {
    //   error:
    //     blurredFormFields?.email && email.length > 0 && !validateEmail(email),
    //   message: "Email is invalid!",
    // },
  };

  const submitButtonDisabled = [formErrors.firstName, formErrors.lastName].some(
    (value) => isAnyKeyValueTrue(value)
  );

  const {
    mutate: handleCustomerProfileUpdate,
    isLoading: isCustomerProfileUpdateLoading,
  } = useMutation({
    mutationFn: updateCustomerProfile,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.cancelQueries({
        queryKey: ["customer", cognitoId],
      });
      queryClient.setQueryData(["customer", cognitoId], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
      // setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    setShowError(false);
    setErrorMessage("");
    handleCustomerProfileUpdate({
      cognitoId,
      accessToken,
      customerFields: {
        firstName,
        lastName,
      },
    });
  };

  return (
    <Modal
      open={open}
      loading={isCustomerProfileUpdateLoading}
      onSubmit={handleSubmit}
      onCancel={() => {
        setOpen(false);
      }}
      handleClose={() => {
        if (!isCustomerProfileUpdateLoading) {
          setOpen(false);
        }
      }}
      submitButtonLabel="Update"
      title="Profile Details"
      submitButtonDisabled={submitButtonDisabled}
    >
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowSuccess(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Customer profile update successful
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
            setErrorMessage("");
          }}
        >
          <Alert
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"First Name"}
              fullWidth
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("firstName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("firstName");
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.firstName))}
              helperText={isAnyKeyValueTrue(formErrors.firstName)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Last Name"}
              fullWidth
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.lastName))}
              helperText={isAnyKeyValueTrue(formErrors.lastName)}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled
              label={"Phone Number"}
              fullWidth
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              label={"Email"}
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CustomerProfileEditModal;
