import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import { useDriverDetails } from "../../../hooks/useDrivers";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getAccessToken } from "../../../helpers/authHelpers";
import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useCustomerDetails } from "../../../hooks/useCustomers";
import ProfilePanel from "../../../components/ProfilePanel/ProfilePanel";
import moment from "moment";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import TabSection from "../../../components/TabSection/TabSection";
import { useContext, useState } from "react";
//   import ServiceCallsTab from "./ServiceCallsTab";
//   import CustomerProfileEditModal from "./CustomerProfileEditModal";
//   import PaymentsTab from "./PaymentsTab";
import { AuthContext } from "../../../context/authContext";
import { useCompanyDetails } from "../../../hooks/useCompanies";
import CompanyProfilePanel from "./CompanyProfilePanel";
import CompanyProfileEditModal from "./CompanyProfileEditModal";
import CompanyBillingSettingsTab from "./CompanyBillingSettingsTab/CompanyBillingSettingsTab";
import CompanyVoucherTypesTab from "./CompanyVoucherTypesTab/CompanyVoucherTypesTab";
import CompanyAccountsTab from "./CompanyAccountsTab/CompanyAccountsTab";

const CompanyDetails = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const { companyId } = useParams();
  const { user } = useAuthenticator((context) => [context.route, context.user]);

  // const accessToken = getAccessToken(user);
  const { accessToken } = useContext(AuthContext);

  const { data: companyData } = useCompanyDetails(accessToken, companyId);
  console.log("companyData", companyData);
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
  };

  const primarySectionFields = [
    {
      label: "Address Line #1",
      value: companyData?.companyDetails?.addressLineOne
        ? companyData?.companyDetails?.addressLineOne
        : "N/A",
    },
    {
      label: "Address Line #2",
      value: companyData?.companyDetails?.addressLineTwo
        ? companyData?.companyDetails?.addressLineTwo
        : "N/A",
    },
    {
      label: "City",
      value: companyData?.companyDetails?.city
        ? companyData?.companyDetails?.city
        : "N/A",
    },
    {
      label: "Province",
      value: companyData?.companyDetails?.province
        ? companyData?.companyDetails?.province
        : "N/A",
    },
    {
      label: "Country",
      value: companyData?.companyDetails?.country
        ? companyData?.companyDetails?.country
        : "N/A",
    },
    {
      label: "Created At",
      value: moment(companyData?.createdAt).format("Do MMMM  YYYY, h:mm:ss a"),
    },
    {
      label: "Updated At",
      value: moment(companyData?.updatedAt).format("Do MMMM  YYYY, h:mm:ss a"),
    },
  ];

  const placeholderTabPage = <Box>Tab page</Box>;

  const tabPages = [
    <CompanyBillingSettingsTab companyId={companyId} />,
    <CompanyVoucherTypesTab accessToken={accessToken} companyId={companyId} />,
    <CompanyAccountsTab accessToken={accessToken} companyId={companyId} />,
  ];

  return (
    <DashboardLayout>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/companies",
            title: "Business Partnership Platform",
          },
          {
            url: `/dashboard/companies/${companyId}`,
            title: `${companyData?.name}`,
          },
        ]}
        pageTitle={`Business Partnership Platform`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/companies"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}
        {/* <CustomerProfileEditModal
            accessToken={accessToken}
            cognitoId={cognitoId}
            open={open}
            setOpen={setOpen}
            customerFirstName={companyData?.firstName}
            customerLastName={companyData?.lastName}
            customerEmail={companyData?.email}
            customerPhoneNumber={companyData?.phoneNumber}
          /> */}
        <CompanyProfileEditModal
          accessToken={accessToken}
          companyId={companyData?.id}
          open={open}
          setOpen={setOpen}
          companyName={companyData?.name}
          companyDescription={companyData?.companyDetails?.description}
          companyAddressLineOne={companyData?.companyDetails?.addressLineOne}
          companyAddressLineTwo={companyData?.companyDetails?.addressLineTwo}
          companyCity={companyData?.companyDetails?.city}
          companyProvince={companyData?.companyDetails?.province}
          companyCountry={companyData?.companyDetails?.country}
          companyLogo={companyData?.companyLogo}
        />
        <CompanyProfilePanel
          name={companyData?.name}
          companyId={companyData?.id}
          companyLogo={companyData?.companyLogo}
          onEditClick={() => {
            setOpen(true);
          }}
        >
          <Grid container spacing={2} direction={"column"}>
            {primarySectionFields.map((field) => (
              <ProfileListItem label={field.label} value={field.value} />
            ))}
          </Grid>
        </CompanyProfilePanel>
        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Paper>
            <TabSection
              tabIndex={tabIndex}
              tabHeaders={["Billing Settings", "SKU", "Accounts"]}
              handleTabChange={(index) => {
                setTabIndex(index);
              }}
            >
              {tabPages[tabIndex]}
            </TabSection>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CompanyDetails;
