import { forwardRef, useState } from "react";
import { IMaskInput } from "react-imask";
import TextField from "@mui/material/TextField";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="{+1} (000)-000-0000"
        definitions={{
          "#": /[1-9]/,
        }}
        unmask={true}
        inputRef={ref}
        onAccept={(value: any, mask: any) => {
          onChange(value);
        }}
        overwrite
      />
    );
  }
);

const PhoneNumberTextField = ({
  label = "PHONE NUMBER",
  placeholder = "Phone Number",
  value,
  error = false,
  setValue,
  onBlur = () => {},
  onFocus = () => {},
}: {
  label?: string;
  placeholder?: string;
  value: any;
  setValue: any;
  error?: boolean;
  onBlur?: any;
  onFocus?: any;
}) => {
  //   const [value, setValue] = useState("");
  const handleChange = (value: any) => {
    console.log("value", value);
    setValue(value);
  };
  return (
    <TextField
      fullWidth
      error={error}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      variant="outlined"
    />
  );
};

export default PhoneNumberTextField;
