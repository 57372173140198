import { useState } from "react";

const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<any>("");
  const [accessTokenLoading, setAccessTokenLoading] = useState(true);

  return {
    accessToken,
    setAccessToken,
    accessTokenLoading,
    setAccessTokenLoading,
  };
};

export default useAccessToken;
