import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// only returns 1 payment method for now
const getCompanyPaymentMethods = async (
  accessToken: string,
  companyId: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/payment-methods`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log("error", error);
  }
};

const addNewCompanyPaymentMethodAndBillingAddress = async ({
  card,
  billingAddress,
  accessToken,
  companyId,
}: {
  card: {
    nameOnCard: any;
    cardNumber: any;
    expiryMonth: any;
    expiryYear: any;
    cvc: any;
    postalCode: any;
  };
  billingAddress: {
    street: any;
    city: any;
    province: any;
    postalCode: any;
  };
  accessToken: string;
  companyId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/add-payment-method-and-billing-address`,
      {
        card,
        billingAddress,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const updateCompanyPaymentMethodAndBillingAddress = async ({
  card,
  billingAddress,
  accessToken,
  companyStripePaymentMethodId,
  companyId,
}: {
  card: {
    nameOnCard: any;
    cardNumber: any;
    expiryMonth: any;
    expiryYear: any;
    cvc: any;
    postalCode: any;
  };
  billingAddress: {
    street: any;
    city: any;
    province: any;
    postalCode: any;
  };
  accessToken: string;
  companyStripePaymentMethodId: string;
  companyId: string;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/update-payment-method-and-billing-address/${companyStripePaymentMethodId}`,
      {
        card,
        billingAddress,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

// this is just 1 payment method right now, not an array of them
const useCompanyPaymentMethods = (accessToken: string, companyId: string) => {
  return useQuery({
    queryKey: ["company-payment-methods", companyId],
    queryFn: () => getCompanyPaymentMethods(accessToken, companyId),
  });
};

export {
  getCompanyPaymentMethods,
  useCompanyPaymentMethods,
  addNewCompanyPaymentMethodAndBillingAddress,
  updateCompanyPaymentMethodAndBillingAddress,
};
