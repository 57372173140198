import { fetchAuthSession } from "aws-amplify/auth";

const getAccessToken = (user: any) => {
  return user?.getSignInUserSession()?.getAccessToken().getJwtToken();
};

const getCognitoAccessToken = async () => {
  try {
    const { accessToken: cognitoAccessToken } =
      (await fetchAuthSession()).tokens ?? {};

    return cognitoAccessToken;
  } catch (err) {
    console.log(err);
  }
};

export { getAccessToken, getCognitoAccessToken };
