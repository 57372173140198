import axios from "axios";

const uploadAssetToCloudinary = async (file: File, folderName: string) => {
  if (
    !process.env.REACT_APP_CLOUDINARY_API_KEY ||
    !process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
  ) {
    return null;
  }

  const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`;
  const formData = new FormData();
  formData.append("api_key", process.env.REACT_APP_CLOUDINARY_API_KEY);
  formData.append("file", file);
  formData.append("resource_type", "auto");
  formData.append("folder", folderName);
  formData.append(
    "upload_preset",
    process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
  );

  const result = await axios.post(CLOUDINARY_URL, formData, {
    validateStatus: () => true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const { public_id, secure_url, resource_type } = result.data;

  return {
    file_id: public_id,
    file_url: secure_url,
    resource_type,
  };
};

export { uploadAssetToCloudinary };
