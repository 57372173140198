import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Drivers from "./pages/Drivers/Drivers";
import Customers from "./pages/Customers/Customers";
import DriverProfile from "./pages/Drivers/DriverProfile/DriverProfile";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import CustomerProfile from "./pages/Customers/CustomerProfile/CustomerProfile";
import ServiceCalls from "./pages/ServiceCalls/ServiceCalls";
import ServiceCallDetails from "./pages/ServiceCalls/ServiceCallDetails/ServiceCallDetails";
import ScheduledServiceCalls from "./pages/ScheduledServiceCalls/ScheduledServiceCalls";
import ScheduledServiceCallDetails from "./pages/ScheduledServiceCalls/ScheduledServiceCallDetails/ScheduledServiceCallDetails";
import ServiceCallSubmissionAttempts from "./pages/ServiceCallSubmissionAttempts/ServiceCallSubmissionAttempts";
import ServiceCallSubmissionAttemptDetails from "./pages/ServiceCallSubmissionAttempts/ServiceCallSubmissionAttemptDetails/ServiceCallSubmissionAttemptDetails";
import useAccessToken from "./hooks/useAccessToken";
import { AuthContext } from "./context/authContext";
import { getCognitoAccessToken } from "./helpers/authHelpers";
import Companies from "./pages/Companies/Companies";
import CompanyDetails from "./pages/Companies/CompanyDetails/CompanyDetails";
const AppRoutes = () => {
  const { user } = useAuthenticator((context) => [context.route, context.user]);
  const { accessToken, setAccessToken } = useAccessToken();

  useEffect(() => {
    console.log("user changed", user);

    const setUserAccessToken = async () => {
      const cognitoAccessToken = await getCognitoAccessToken();
      setAccessToken(cognitoAccessToken);
    };

    if (user) {
      setUserAccessToken();
    } else {
      setAccessToken(null);
    }
  }, [user]);
  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken }}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="dashboard">
            <Route
              index
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="drivers"
              element={
                <RequireAuth>
                  <Drivers />
                </RequireAuth>
              }
            />
            <Route
              path="drivers/:cognitoId"
              element={
                <RequireAuth>
                  <DriverProfile />
                </RequireAuth>
              }
            />
            <Route
              path="customers"
              element={
                <RequireAuth>
                  <Customers />
                </RequireAuth>
              }
            />
            <Route
              path="customers/:cognitoId"
              element={
                <RequireAuth>
                  <CustomerProfile />
                </RequireAuth>
              }
            />
            <Route
              path="service-calls"
              element={
                <RequireAuth>
                  <ServiceCalls />
                </RequireAuth>
              }
            />
            <Route
              path="service-calls/:serviceCallId"
              element={
                <RequireAuth>
                  <ServiceCallDetails />
                </RequireAuth>
              }
            />
            <Route
              path="scheduled-service-calls"
              element={
                <RequireAuth>
                  <ScheduledServiceCalls />
                </RequireAuth>
              }
            />
            <Route
              path="scheduled-service-calls/:serviceCallId"
              element={
                <RequireAuth>
                  <ScheduledServiceCallDetails />
                </RequireAuth>
              }
            />
            <Route
              path="service-call-submission-attempts"
              element={
                <RequireAuth>
                  <ServiceCallSubmissionAttempts />
                </RequireAuth>
              }
            />
            <Route
              path="service-call-submission-attempts/:serviceCallSubmissionAttemptId"
              element={
                <RequireAuth>
                  <ServiceCallSubmissionAttemptDetails />
                </RequireAuth>
              }
            />
            <Route
              path="companies"
              element={
                <RequireAuth>
                  <Companies />
                </RequireAuth>
              }
            />
            <Route
              path="companies/:companyId"
              element={
                <RequireAuth>
                  <CompanyDetails />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

function App() {
  const queryClient = new QueryClient();
  return (
    <Authenticator.Provider>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
      </QueryClientProvider>
    </Authenticator.Provider>
  );
}

export default App;
