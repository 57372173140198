import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImageModal = ({
  open,
  handleClose,
  title,
  imageURL,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  imageURL: string;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle component={"div"} sx={{ py: 1 }}>
        <Grid
          container
          justifyContent={"space-between"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Grid item>{title}</Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid item>
            <img
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              src={imageURL}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
    </Dialog>
  );
};

export default ImageModal;
