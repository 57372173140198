import { Typography, Grid, Alert, Snackbar } from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCompanyPickUpRequestVoucherType } from "../../../../hooks/useCompanyPickUpRequestVoucherTypes";
import { useState } from "react";

const CompanyVoucherDeleteModal = ({
  accessToken,
  companyId,
  open,
  setOpen,
  selectedCompanyVoucher,
  setSelectedCompanyVoucher,
}: {
  accessToken: string;
  companyId: string;
  selectedCompanyVoucher: any;
  setSelectedCompanyVoucher: any;
  open: boolean;
  setOpen: any;
}) => {
  const queryClient = useQueryClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const {
    mutate: handleDeleteCompanyVoucherType,
    isLoading: isDeleteCompanyVoucherTypeLoading,
  } = useMutation({
    mutationFn: deleteCompanyPickUpRequestVoucherType,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });

      await queryClient.cancelQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["company-pickup-request-voucher-types", companyId],
      });
      setOpen(false);
      setSelectedCompanyVoucher(null);
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error adding new payment method", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    handleDeleteCompanyVoucherType({
      accessToken,
      companyId,
      companyPickUpRequestVoucherTypeId: selectedCompanyVoucher?.id,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompanyVoucher(null);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          SKU type deleted
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={isDeleteCompanyVoucherTypeLoading}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        title={"Confirm SKU deletion"}
        submitButtonLabel="Delete"
      >
        <Grid item>
          <Typography>
            Are you sure you want to delete this SKU?{" "}
            <Typography fontWeight={700} component="span">
              This action is permanent and can result in data loss.
            </Typography>
          </Typography>
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyVoucherDeleteModal;
