import {
  Alert,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import useFormFieldsBlurState from "../../../../hooks/useFormFieldsBlurState";
import {
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  removeWhitespaces,
  validateEmail,
  validatePhoneNumber,
  validatePresence,
} from "../../../../helpers/validationHelpers";
import ErrorHelperText from "../../../../components/ErrorHelperText/ErrorHelperText";
import PhoneNumberTextField from "../../../../components/PhoneNumberTextField/PhoneNumberTextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addNewCompanyStaff } from "../../../../hooks/useCompanyUsers";

const CompanyAccountUserInviteModal = ({
  open,
  setOpen,
  accessToken,
  companyId,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  accessToken: string;
  companyId: string;
}) => {
  const queryClient = useQueryClient();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("MEMBER");
  const [submitErrors, setSubmitErrors] = useState<any[]>([]);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    firstName,
    lastName,
    phoneNumber,
    email,
    role,
  });

  const formErrors = {
    firstName: {
      empty:
        blurredFormFields.firstName &&
        !validatePresence(firstName) &&
        "Please enter a first name",
    },
    lastName: {
      empty:
        blurredFormFields.lastName &&
        !validatePresence(lastName) &&
        "Please enter a last name",
    },
    phoneNumber: {
      empty:
        blurredFormFields?.phoneNumber &&
        !validatePresence(phoneNumber) &&
        "Please enter a phone number",
      invalid:
        blurredFormFields?.phoneNumber &&
        phoneNumber.length > 0 &&
        !validatePhoneNumber(phoneNumber) &&
        "Please enter a valid phone number",
    },
    email: {
      empty:
        blurredFormFields?.email &&
        !validatePresence(email) &&
        "Please enter an email",
      invalid:
        blurredFormFields?.email &&
        email.length > 0 &&
        !validateEmail(email) &&
        "Please enter a valid email",
    },
    role: {
      empty:
        blurredFormFields?.role &&
        !["MEMBER", "ADMIN"].includes(role) &&
        "Please select a user role",
    },
  };

  const firstNameErrors: any = filterObjectByValues(
    formErrors?.firstName,
    (key, value) => value
  );
  const lastNameErrors: any = filterObjectByValues(
    formErrors?.lastName,
    (key, value) => value
  );
  const phoneNumberErrors: any = filterObjectByValues(
    formErrors?.phoneNumber,
    (key, value) => value
  );

  const emailErrors: any = filterObjectByValues(
    formErrors?.email,
    (key, value) => value
  );

  const roleErrors: any = filterObjectByValues(
    formErrors?.role,
    (key, value) => value
  );

  const serverSideEmailErrors = submitErrors?.filter(
    (error: any) => error?.name === "email"
  );

  const serverSidePhoneNumberErrors = submitErrors?.filter(
    (error: any) => error?.name === "phoneNumber"
  );

  const userErrors = submitErrors?.filter(
    (error) => error?.name === "USER_ERROR"
  );

  const serverErrors = submitErrors?.filter(
    (error) => error?.name === "SERVER_ERROR"
  );

  const submitDisabled =
    !validatePresence(firstName) ||
    !validatePresence(lastName) ||
    !validatePresence(phoneNumber) ||
    !validatePhoneNumber(phoneNumber) ||
    !validatePresence(email) ||
    !validateEmail(email) ||
    !["MEMBER", "ADMIN"].includes(role);

  const resetFormFields = () => {
    resetBlurState();
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setRole("MEMBER");
    setSubmitErrors([]);
  };

  const { mutate: inviteNewUser, isLoading: isInviteNewUserLoading } =
    useMutation({
      mutationFn: addNewCompanyStaff,
      onSuccess: async (data: any) => {
        console.log("data after submit", data);
        await queryClient.cancelQueries({
          queryKey: ["company-users", companyId],
        });

        await queryClient.invalidateQueries({
          queryKey: ["company-users", companyId],
        });

        setOpen(false);
        // setAlert({
        //   type: "SUCCESS",
        //   message: "USER INVITE SENT",
        // });
        setSuccessMessage("User invite sent");
        resetFormFields();
      },
      onError: async (error: any) => {
        console.log("error", error);
        // setShowError(true);
        // setErrorMessage(error);
        setSubmitErrors(error);

        // setAlert({
        //   type: "ERROR",
        //   message: "FAILED TO INVITE USER",
        // });
      },
    });

  const handleSubmit = () => {
    setSubmitErrors([]);
    inviteNewUser({
      accessToken,
      companyId,
      firstName,
      lastName,
      email: removeWhitespaces(email),
      phoneNumber,
      companyAccountRole: role,
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
    // setSelectedCompanyVoucher(null);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Modal
        loading={isInviteNewUserLoading}
        open={open}
        onSubmit={handleSubmit}
        handleClose={handleClose}
        onCancel={handleClose}
        cancelButtonLabel="Cancel"
        submitButtonLabel="Confirm"
        title="Add User"
        submitButtonDisabled={submitDisabled}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              error={objectHasKeys(firstNameErrors)}
              fullWidth
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("firstName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("firstName");
              }}
              required
              label="First Name"
              variant="outlined"
            />
            {mapObjectValues(firstNameErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`account-details-first-name-errors-${index}`}
                errorText={firstNameErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={objectHasKeys(lastNameErrors)}
              fullWidth
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("lastName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("lastName");
              }}
              required
              label="Last Name"
              variant="outlined"
            />
            {mapObjectValues(lastNameErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`account-details-last-name-errors-${index}`}
                errorText={lastNameErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <PhoneNumberTextField
              label="Phone Number"
              placeholder="Phone Number"
              error={objectHasKeys(phoneNumberErrors)}
              value={phoneNumber}
              setValue={setPhoneNumber}
              onFocus={() => {
                setFormFieldToFocused("phoneNumber");
                setSubmitErrors((prev: any) =>
                  prev?.filter((error: any) => error?.name !== "phoneNumber")
                );
                setSubmitErrors((prev: any) =>
                  prev?.filter((error: any) => error?.name !== "USER_ERROR")
                );
              }}
              onBlur={() => {
                setFormFieldToBlurred("phoneNumber");
              }}
            />
            {mapObjectValues(phoneNumberErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`account-details-phone-number-errors-${index}`}
                errorText={phoneNumberErrors[key]}
              />
            ))}
            {serverSidePhoneNumberErrors.map((error: any, index: any) => (
              <ErrorHelperText
                key={`account-details-server-side-phone-number-errors-${index}`}
                errorText={error.message}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={objectHasKeys(emailErrors)}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("email");
                setSubmitErrors((prev: any) =>
                  prev?.filter((error: any) => error?.name !== "email")
                );
                setSubmitErrors((prev: any) =>
                  prev?.filter((error: any) => error?.name !== "USER_ERROR")
                );
              }}
              onBlur={() => {
                setFormFieldToBlurred("email");
              }}
              required
              label="Email"
              variant="outlined"
            />
            {mapObjectValues(emailErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`account-details-emails-errors-${index}`}
                errorText={emailErrors[key]}
              />
            ))}
            {serverSideEmailErrors.map((error: any, index) => (
              <ErrorHelperText
                key={`account-details-server-side-email-errors-${index}`}
                errorText={error.message}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontSize={11}>Role</Typography>
              <Select
                error={objectHasKeys(roleErrors)}
                sx={{
                  "&.Mui-disabled": {
                    "&:before": {
                      borderBottomStyle: "solid",
                    },
                  },
                }}
                value={role}
                label="Role"
                onChange={(e: any) => {
                  setRole(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("role");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("role");
                }}
                variant="outlined"
              >
                <MenuItem value={"MEMBER"}>Member</MenuItem>
                <MenuItem value={"ADMIN"}>Admin</MenuItem>
              </Select>
            </FormControl>
            {mapObjectValues(roleErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`account-details-role-errors-${index}`}
                errorText={roleErrors[key]}
              />
            ))}
          </Grid>
          {userErrors?.length > 0 && (
            <Grid container item spacing={1}>
              {userErrors?.map((error: any) => (
                <Grid item xs={12}>
                  <Alert severity="error">{error?.message}</Alert>
                </Grid>
              ))}
            </Grid>
          )}
          {serverErrors?.length > 0 && (
            <Grid container item spacing={1}>
              {serverErrors?.map((error: any) => (
                <Grid item xs={12}>
                  <Alert severity="error">{error?.message}</Alert>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyAccountUserInviteModal;
