import { Box, Grid, Hidden, Typography } from "@mui/material";
import LeftLoginSection from "../Login/LeftLoginSection/LeftLoginSection";
import ForgotPasswordForm from "./ForgotPasswordForm";
const ForgotPassword = () => {
  return (
    <Grid container height="100vh">
      <LeftLoginSection />
      <ForgotPasswordForm />
    </Grid>
  );
};

export default ForgotPassword;
